import React from 'react'
import { RouteComponentProps } from '@reach/router'
import imgHappy from '../../images/smile2.svg'

const Home: React.FC<RouteComponentProps> = () => {
  return (
    // <div
    //   className="bg-cover bg-center flex justify-center items-end"
    //   style={{
    //     backgroundImage:
    //       'url(https://images.unsplash.com/photo-1506260408121-e353d10b87c7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1700&q=80), linear-gradient(hsla(81,30%,90%,0),hsla(81,30%,30%,1))',
    //     backgroundBlendMode: 'overlay',
    //     height: 'calc(100vh - 222px)',
    //   }}
    // >
    //   <div
    //     className="text-5xl white py-10 font-thin text-center leading-none"
    //     style={{
    //       color: 'hsl(81, 56%, 90%)',
    //       textShadow: '0 0 10px hsla(96, 50%, 4%, 0.7)',
    //     }}
    //   >
    //     Bentornato, Matteo
    //     <br />
    //     <span className="text-3xl font-semibold">Buon lavoro!</span>
    //   </div>
    // </div>
    <div className="max-w-2xl mx-auto pt-16 pb-8 flex flex-col justify-center items-center">
      <p className="text-4xl font-thin text-center text-blue-800 mb-16">Bentornato, Matteo!</p>
      <img src={imgHappy} alt="" style={{ width: '24rem'}} />
    </div>
  )
}

export default Home
