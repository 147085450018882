import React, { useContext } from 'react'
import { Router, RouteComponentProps, Link } from '@reach/router'
import classNames from 'classnames'
import moment from 'moment'

import AnnoScolasticoContext from '../../../context/AnnoScolasticoContext'
import Dati from './Dati'
import Intestatari from './intestatari'
import ElencoIntestatari from './intestatari/Elenco'
import Intestatario from './intestatari/Intestatario'
import Servizio from './Servizio'
import { getSesso } from '../../../lib/codiceFiscale'
import Loading from '../../../components/Loading'

import useProfile from '../../../apiHooks/queries/useProfile'
import useAlunnoByIdByAnno from '../../../apiHooks/queries/useAlunnoByIdByAnno'
import useScuole from '../../../apiHooks/queries/useScuole'
import useServizi from '../../../apiHooks/queries/useServizi'

import imgAlunno from '../../../images/alunno.svg'
import imgAlunna from '../../../images/alunna.svg'

const isActive = ({ isCurrent }: { isCurrent: boolean }) => {
  return isCurrent
    ? {
        className:
          'mr-4 py-1 px-3 bg-blue-200 border-blue-200 text-blue-800 text-sm font-medium rounded',
      }
    : {}
}

const isPartiallyActive = ({
  isPartiallyCurrent,
}: {
  isPartiallyCurrent: boolean
}) => {
  return isPartiallyCurrent
    ? {
        className:
          'mr-4 py-1 px-3 bg-blue-200 border-blue-200 text-blue-800 text-sm font-medium rounded',
      }
    : {}
}

interface AlunnoProps extends RouteComponentProps {
  id?: number
}

const Alunno: React.FC<AlunnoProps> = ({ children, id }) => {
  const token = localStorage.getItem('token')
  const { data: user } = useProfile(token)
  const { annoScolastico } = useContext(AnnoScolasticoContext)

  const {
    data: alunno,
    error: errorAlunno,
    isFetching: isFetchingAlunno,
  } = useAlunnoByIdByAnno({
    alunnoId: id,
    annoScolastico,
  })

  const {
    data: scuole,
    error: errorScuole,
    isFetching: isFetchingScuole,
  } = useScuole()

  const sesso = alunno && getSesso(alunno.codiceFiscale || '')
  const eta = alunno && moment().diff(moment(alunno.dataNascita, 'YYYY-MM-DD', true), 'years')

  const { data: servizi } = useServizi()

  const renderNomiServizi =
    user &&
    user.servizi.map((sId: any) => {
      const nomeServizio =
        servizi && servizi.find((s: any) => s.id === sId).nome
      const isAttivo =
        alunno && alunno.fruizioni.find((f: any) => f.servizioId === sId)

      return (
        <Link
          key={sId}
          to={`servizio/${sId}`}
          className={`mr-4 py-1 px-3 border border-gray-100 hover:bg-white hover:border-blue-200 text-sm rounded transition-colors duration-150 ${
            !!isAttivo ? 'text-gray-700' : 'text-gray-500'
          }`}
          getProps={isActive}
        >
          <span className="capitalize">{nomeServizio}</span>
        </Link>
      )
    })

  if (isFetchingScuole || isFetchingAlunno) {
    return <Loading className="py-12" />
  }

  if (errorScuole || errorAlunno) {
    return <span>Si è verificato un errore</span>
  }

  if (!alunno.sezione) {
    return (
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <h1 className="text-4xl mb-4 leading-tight">
          {alunno.nome} {alunno.cognome}
        </h1>
        Questo alunno non ha sezione assegnata o fruizioni per l'anno corrente:
        vedere{' '}
        {alunno.sezioni.map((s: any) => (
          <span key={s.annoScolastico} className="py-1 px-2 bg-blue-200 rounded mr-2">
            {s.annoScolastico}
          </span>
        ))}
      </div>
    )
  }

  const scuola =
    scuole && alunno.sezione
      ? scuole.find((s: any) => s.id === alunno.sezione.scuolaId)
      : {}

  return (
    <div>
      <div className="bg-white py-8 border-b border-gray-300">
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row items-start justify-between">
            <div className="flex items-center">
              <img
                src={sesso === 'F' ? imgAlunna : imgAlunno}
                alt={alunno.nome + ' ' + alunno.cognome}
                className={classNames(
                  'w-24 h-24 p-1 rounded-full mr-8 border',
                  { 'bg-red-100 border-red-200': sesso === 'F' },
                  { 'bg-blue-100 border-blue-200': sesso !== 'F' }
                )}
              />
              <div>
                <h1 className="text-4xl mb-2 leading-tight">
                  {alunno.nome} {alunno.cognome}
                </h1>

                <div className="flex items-center">
                  <h2 className="py-1 pr-5 border-r border-gray-300 mr-5 text-gray-600">
                    {eta || '-'} {eta === 1 ? 'anno' : 'anni'}
                  </h2>
                  <h2 className="py-1 pr-5 border-r border-gray-300 mr-5 capitalize text-gray-600">
                    {scuola.tipo + ' ' + scuola.nome}
                  </h2>
                  <h2 className="py-1 text-gray-600">
                    {alunno.sezione && (
                      <span>
                        {alunno.sezione.classe}
                        <sup>a</sup> {alunno.sezione.sezione}
                      </span>
                    )}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:pl-32 py-6 flex">
          <Link
            to="./"
            className="mr-4 py-1 px-3 border border-gray-100 hover:border-blue-200 hover:bg-white text-gray-700 text-sm rounded transition-colors duration-150"
            getProps={isActive}
          >
            Dati
          </Link>
          <Link
            to="intestatari"
            className="mr-4 py-1 px-3 border border-gray-100 hover:border-blue-200 hover:bg-white text-gray-700 text-sm rounded transition-colors duration-150"
            getProps={isPartiallyActive}
          >
            Intestatari
          </Link>
          {renderNomiServizi}
        </div>

        {alunno && (
          <Router primary={false} className="pb-10">
            <Dati
              path="/"
              alunno={alunno}
              scuole={scuole}
              intestatari={alunno.intestatari}
            />
            <Intestatari path="intestatari">
              <ElencoIntestatari
                path="/"
                intestatari={alunno.intestatari}
                fruizioni={alunno.fruizioni}
                servizi={servizi}
              />
              <Intestatario path="/:intestatarioId" alunnoId={alunno.id} />
              <Intestatario path="/nuovo" alunnoId={alunno.id} />
            </Intestatari>
            <Servizio
              path="servizio/:id"
              fruizioni={alunno.fruizioni}
              intestatari={alunno.intestatari}
              alunnoId={alunno.id}
            />
          </Router>
        )}
      </div>
    </div>
  )
}

export default Alunno
