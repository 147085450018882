import React, { useState, useEffect, useContext } from 'react'
import { RouteComponentProps, Link } from '@reach/router'
import ReactModal from 'react-modal'
import InlineEdit, { InputType } from 'riec'
import moment from 'moment'
import { FiFileText, FiEye } from 'react-icons/fi'
import { FcUnlock } from 'react-icons/fc'
import { FaCircle } from 'react-icons/fa'
import { useForm } from 'react-hook-form'

import { API_URL } from '../../config'
import ServizioContext from '../../context/ServizioContext'

import { AgGridReact } from 'ag-grid-react'
import { CellClassParams } from 'ag-grid-community'
import classNames from 'classnames'

import {
  TipoPresenza,
  isPresente,
  isAssente,
  isFestivita,
  isChiusuraNoFestivita,
  isWeekend,
  isErroreSegnato,
  isErroreDimenticato,
  isWarning,
} from '../../lib/validazionePresenze'
import { hasAnomalieFruizioni } from '../../lib/controlloAnomalie'
import usePresenzeMese from '../../apiHooks/queries/usePresenzeMese'
import AnnoScolasticoContext from '../../context/AnnoScolasticoContext'
import useGiorniNonErogazioneByServizioId from '../../apiHooks/queries/useGiorniNonErogazioneByServizioId'
import useScuoleByServizioId from '../../apiHooks/queries/useScuoleByServizioId'
import useBollettaFruizioneMese from '../../apiHooks/queries/useBollettaFruizioneMese'
import useAlunniDaControllare from '../../apiHooks/queries/useAlunniDaControllare'
import useFruizioniDaControllare from '../../apiHooks/queries/useFruizioniDaControllare'
import useStatoAvvisiPagamento from '../../apiHooks/queries/useStatoAvvisiPagamento'
import useAvvisoPagamentoById from '../../apiHooks/queries/useAvvisoPagamentoById'
import useGeneraAvvisiPagamento from '../../apiHooks/mutations/useGeneraAvvisiPagamento'

import Loading from '../../components/Loading'
import FormField, { InputType as formFieldInputType } from '../../components/FormField'

interface Sezione {
  id: number
  scuolaId: number
  annoScolastico: string
  classe: string
  sezione: string
}

interface Presenze {
  id: number
  data: string
  tipo: string
  fruizioneId: number
}

interface Fruizione {
  [giorno: string]: boolean
}

interface Alunno {
  codiceFiscale: string
  nome: string
  cognome: string
  sezione: Sezione
  presenze: Presenze[]
  fruizione: Fruizione
}

interface AlunnoBolletta {
  nome: string
  cognome: string
  fruizioneId: number
}

interface RigaBolletta {
  descrizione: string
  importo: number
  isRetta?: boolean
}

interface Bolletta {
  id: number
  fruizioneId: number
  mese: number
  anno: number
  righe: RigaBolletta[]
  totale: number
}

const modalCustomStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    padding: 0,
    border: 'none',
  },
  overlay: {
    backgroundColor: 'hsla(207, 40%, 60%, 0.7)',
  },
}

const PresenzeMensili: React.FC<RouteComponentProps> = () => {
  //===================
  // CONTEXT
  //===================
  const { servizioId } = useContext(ServizioContext)
  const { annoScolastico } = useContext(AnnoScolasticoContext)

  //===================
  // LOCAL STATE
  //===================
  const [scuolaSel, setScuolaSel] = useState('1')
  const [meseSel, setMeseSel] = useState<string>(moment().format('MM'))
  const [annoSel, setAnnoSel] = useState<string>(moment().format('YYYY'))
  const [alunni, setAlunni] = useState<Alunno[]>([])
  const [bolletteGenerate, setBolletteGenerate] = useState<boolean>(false)
  const [numeroErrori, setNumeroErrori] = useState({
    numeroErrori: 0,
    numeroWarning: 0,
    numeroAnomalie: 0,
    numeroAnomalieFruizione: 0,
  })
  const [fruizioneIdSel, setFruizioneId] = useState<number>(0)
  const [avvisoId, setAvvisoId] = useState<number>(0)
  const [righeAvvisoModale, setRigheAvvisoModale] = useState<any[]>([])
  const [isPdf, setIsPdf] = useState(false)
  const [modalBollettaOpen, setModalBollettaOpen] = useState<boolean>(false)
  const [modalAvvisoOpen, setModalAvvisoOpen] = useState<boolean>(false)
  const [formModalOpen, setFormModalOpen] = useState<boolean>(false)
  // const [bolletta, setBolletta] = useState<Bolletta | null>(null)
  const [alunnoBolletta, setAlunnoBolletta] = useState<AlunnoBolletta | null>(null)

  //===================
  // HOOKS QUERIES
  //===================
  const { data: presenzeMensili = [], isFetching: isFetchingPresenze } = usePresenzeMese({
    servizioId,
    scuolaId: scuolaSel,
    mese: parseInt(meseSel),
    anno: parseInt(annoSel),
    annoScolastico,
  })

  const { data: statoAvvisiPagamento } = useStatoAvvisiPagamento({
    servizioId,
    scuolaId: scuolaSel,
    mese: parseInt(meseSel),
    anno: parseInt(annoSel),
  })

  const { data: giorniNonErogazione = [] } = useGiorniNonErogazioneByServizioId({
    servizioId,
    from: moment(`${annoSel}-${meseSel}-01`, 'YYYY-MM-DD', true)
      .startOf('month')
      .format('YYYY-MM-DD'),
    to: moment(`${annoSel}-${meseSel}-01`, 'YYYY-MM-DD', true).endOf('month').format('YYYY-MM-DD'),
  })

  const { data: scuole, isFetching: isFetchingScuole } = useScuoleByServizioId(servizioId)
  const scuoleOptions =
    scuole &&
    scuole.map((scuola: any) => {
      return {
        value: scuola.id,
        label: scuola.nome,
      }
    })

  const mesiOptions = [
    { value: '09', label: 'Settembre' },
    { value: '10', label: 'Ottobre' },
    { value: '11', label: 'Novembre' },
    { value: '12', label: 'Dicembre' },
    { value: '01', label: 'Gennaio' },
    { value: '02', label: 'Febbraio' },
    { value: '03', label: 'Marzo' },
    { value: '04', label: 'Aprile' },
    { value: '05', label: 'Maggio' },
    { value: '06', label: 'Giugno' },
    { value: '07', label: 'Luglio' },
    { value: '08', label: 'Agosto' },
  ]

  const { data: bollettaMese, isFetching: isFetchingBollettaMese } = useBollettaFruizioneMese({
    fruizioneId: fruizioneIdSel,
    anno: moment(annoSel, 'YYYY', true).format('YYYY'),
    mese: moment(meseSel, 'MM', true).format('M'),
  })

  const { data: alunniDaControllare = [] } = useAlunniDaControllare({
    annoScolastico,
  })

  const { data: alunniConAnomalieFruizioni = [] } = useFruizioniDaControllare({
    annoScolastico,
    servizioId,
    scuolaId: parseInt(scuolaSel),
  })

  const { data: avvisoPagamento } = useAvvisoPagamentoById(avvisoId)

  const { register, handleSubmit, errors } = useForm({
    defaultValues: { dataEmissione: '', dataScadenza: '', periodo: '', nota: '' },
  })

  //===================
  // EFFECTS
  //===================
  useEffect(() => {
    const alunnoBolletta = presenzeMensili.find((p: any) => p.fruizione.id === fruizioneIdSel)
    setAlunnoBolletta(alunnoBolletta)
  }, [isFetchingPresenze, fruizioneIdSel, isFetchingBollettaMese])

  useEffect(() => {
    const scuolaId = scuole && scuole[0].id + ''
    scuole && setScuolaSel(scuolaId)
  }, [isFetchingScuole])

  useEffect(() => {
    // ANTEPRIMA
    if (avvisoPagamento && avvisoPagamento.righeImporti) {
      const righeAvvisoPagamento = JSON.parse(avvisoPagamento.righeImporti)

      if (righeAvvisoPagamento && righeAvvisoPagamento.length) {
        setRigheAvvisoModale(righeAvvisoPagamento)
      }
    }
    // PDF
    if (avvisoPagamento && isPdf) {
      window.open(
        `${avvisoPagamento.linkDownload}?t=${encodeURIComponent(
          localStorage.getItem('token') || ''
        )}`
      )
    }
  }, [avvisoPagamento])

  useEffect(() => {
    const anni: string[] = annoScolastico.split('-')
    const anno = parseInt(meseSel, 10) < 8 ? anni[1] : anni[0]
    setAnnoSel(anno)
  }, [annoScolastico, meseSel])

  const giorniNelMese = moment(`${annoSel}-${meseSel}-01`, 'YYYY-MM-DD', true).daysInMonth()
  const giorniDelMese: number[] = []
  for (let i: number = 1; i <= giorniNelMese; i++) {
    giorniDelMese.push(i)
  }

  const handleAnteprimaBolletta = (id: number, avvisoCreato: boolean) => (event: any) => {
    setIsPdf(false)
    document.getElementById('root')!.style.filter = 'blur(5px)'

    if (!!avvisoCreato) {
      setAvvisoId(id)
      setModalAvvisoOpen(true)
    } else {
      setFruizioneId(id)
      setModalBollettaOpen(true)
    }
  }

  const handlePdfAvviso = (id: number) => (event: any) => {
    setIsPdf(true)
    setAvvisoId(id)
  }

  const handleGeneraBollette = () => {
    setTimeout(() => {
      setBolletteGenerate(true)
    }, 1000)
  }

  const columnDefs = [
    {
      headerName: 'Classe',
      field: 'sezione',
      sortable: true,
      filter: true,
      floatingFilter: true,
      pinned: true,
      width: 120,
    },
    {
      headerName: 'Cognome',
      field: 'cognome',
      sortable: true,
      filter: true,
      floatingFilter: true,
      width: 140,
      pinned: true,
      cellRendererFramework: (params: any) => (
        <Link
          className="text-blue-500 hover:text-blue-600"
          to={`/app/anagrafica/alunno/${params.data.id}/servizio/${servizioId}`}
        >
          {params.value}
        </Link>
      ),
    },
    {
      headerName: 'Nome',
      field: 'nome',
      filter: true,
      floatingFilter: true,
      width: 140,
      pinned: true,
    },
    {
      headerName: 'Avviso',
      width: 130,
      cellRendererFramework: (params: any) => {
        return (
          <div className="h-full flex justify-start items-center">
            <button
              className="bg-green-600 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-1 px-1 rounded transition-colors duration-150 hover:bg-green-700 hover:shadow-xl"
              onClick={handleAnteprimaBolletta(
                !!params.data.avviso ? params.data.avviso : params.data.fruizione.id,
                !!params.data.avviso
              )}
              disabled={!(params.data.avviso || params.data.fruizione.id)}
            >
              <FiEye />
            </button>
            {!!params.data.avviso ? (
              <button
                className="ml-3 bg-blue-600 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-1 px-1 rounded transition-colors duration-150 hover:bg-blue-700 hover:shadow-xl"
                onClick={handlePdfAvviso(params.data.avviso)}
              >
                <FiFileText />
              </button>
            ) : (
              <a
                className="ml-3 bg-blue-600 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-1 px-1 rounded transition-colors duration-150 hover:bg-blue-700 hover:shadow-xl"
                target="_blank"
                rel="noopener noreferrer"
                href={`${API_URL}/fruizioni/${
                  params.data.fruizione.id
                }/pdf/?anno=${annoSel}&mese=${moment(meseSel, 'MM', true).format(
                  'M'
                )}&t=${encodeURIComponent(localStorage.getItem('token') || '')}`}
              >
                <FiFileText />
              </a>
            )}
            {!!params.data.avviso ? (
              <div className="ml-3 text-green-500" title={`Avviso creato ${params.data.avviso}`}>
                <FaCircle />
              </div>
            ) : (
              <div className="ml-3 text-gray-400" title="Avviso non creato">
                <FaCircle />
              </div>
            )}
            {!!params.data.calcoloSbloccato && (
              <div className="text-lg ml-2" title="Calcolo sbloccato">
                <FcUnlock />
              </div>
            )}
          </div>
        )
      },
    },
    {
      headerName: 'Totale',
      field: 'totale',
      width: 100,
      cellRendererFramework: (params: any) => {
        return (
          <div className="h-full flex justify-center items-center">
            <div
              className={classNames('h-5 px-2 flex justify-center items-center text-xs', {
                'bg-yellow-300': params.value === 0,
              })}
            >
              {params.value}
            </div>
          </div>
        )
      },
    },
    {
      headerName: 'Dett. presenze',
      children: [
        {
          headerName: 'Assenze',
          field: 'numeroAssenze',
          cellClass: 'text-center',
          width: 95,
        },
        {
          headerName: 'Errori',
          cellClass: 'text-center',
          width: 130,
          cellRendererFramework: (params: any) => {
            return (
              <div className="h-full flex justify-start items-center">
                {!!params.data.numeroErrori ? (
                  <span className="w-5 h-5 flex justify-center items-center bg-red-700 text-xs text-white rounded-full">
                    {params.data.numeroErrori}
                  </span>
                ) : (
                  <span className="w-5 h-5"></span>
                )}
                {!!params.data.numeroWarning && (
                  <span className="ml-2 w-5 h-5 flex justify-center items-center bg-yellow-500 text-xs text-white rounded-full">
                    {params.data.numeroWarning}
                  </span>
                )}
                {!!params.data.hasAnomalie && (
                  <span className="ml-2 w-5 h-5 flex justify-center items-center bg-teal-400 text-xs text-white rounded-full">
                    !
                  </span>
                )}
                {!!params.data.hasAnomalieFruizione && (
                  <span className="ml-2 w-5 h-5 flex justify-center items-center bg-purple-400 text-xs text-white rounded-full">
                    !
                  </span>
                )}
              </div>
            )
          },
        },
        ...giorniDelMese.map((g) => {
          return {
            headerName: g + '',
            field: g + '',
            columnGroupShow: 'open',
            width: 55,
            cellClass: 'text-center',
            cellClassRules: {
              'text-blue-500 font-bold': ({ value, colDef, data }: CellClassParams) =>
                // IS PRESENTE OK
                isPresente(value) &&
                !isWarning(
                  moment(
                    `${annoSel}-${meseSel}-${parseInt(colDef.field!, 10)}`,
                    'YYYY-MM-D',
                    true
                  ).format('YYYY-MM-DD'),
                  value,
                  data.fruizione
                ) &&
                !isErroreSegnato(
                  value,
                  giorniNonErogazione,
                  data.fruizione,
                  moment(
                    `${annoSel}-${meseSel}-${parseInt(colDef.field!, 10)}`,
                    'YYYY-MM-D',
                    true
                  ).format('YYYY-MM-DD')
                ),
              'text-gray-400': ({ data, value, colDef }: CellClassParams) =>
                // IS ASSENTE OK
                !isPresente(value) &&
                !isErroreSegnato(
                  value,
                  giorniNonErogazione,
                  data.fruizione,
                  moment(
                    `${annoSel}-${meseSel}-${parseInt(colDef.field!, 10)}`,
                    'YYYY-MM-D',
                    true
                  ).format('YYYY-MM-DD')
                ),
              'bg-purple-200': ({ colDef }: CellClassParams) =>
                // IS CHIUSURA (NO FESTIVITÀ)
                isChiusuraNoFestivita(
                  giorniNonErogazione,
                  moment(
                    `${annoSel}-${meseSel}-${parseInt(colDef.field!, 10)}`,
                    'YYYY-MM-D',
                    true
                  ).format('YYYY-MM-DD')
                ),
              'bg-gray-200': ({ colDef }: CellClassParams) =>
                // IS WEEKEND O FESTIVITÀ
                isWeekend(
                  moment(
                    `${annoSel}-${meseSel}-${parseInt(colDef.field!, 10)}`,
                    'YYYY-MM-D',
                    true
                  ).format('YYYY-MM-DD')
                ) ||
                isFestivita(
                  giorniNonErogazione,
                  moment(
                    `${annoSel}-${meseSel}-${parseInt(colDef.field!, 10)}`,
                    'YYYY-MM-D',
                    true
                  ).format('YYYY-MM-DD')
                ),
              'bg-red-300': ({ value, colDef, data }: CellClassParams) =>
                // IS ERRORE DIMENTICATO
                isErroreDimenticato(
                  value,
                  giorniNonErogazione,
                  data.fruizione,
                  moment(
                    `${annoSel}-${meseSel}-${parseInt(colDef.field!, 10)}`,
                    'YYYY-MM-D',
                    true
                  ).format('YYYY-MM-DD')
                ),
              'text-red-500 font-bold': ({ value, colDef, data }: CellClassParams) =>
                // IS ERRORE SEGNATO
                isErroreSegnato(
                  value,
                  giorniNonErogazione,
                  data.fruizione,
                  moment(
                    `${annoSel}-${meseSel}-${parseInt(colDef.field!, 10)}`,
                    'YYYY-MM-D',
                    true
                  ).format('YYYY-MM-DD')
                ),
              'text-yellow-500 font-bold': ({ value, colDef, data }: CellClassParams) =>
                // IS WARNING
                isWarning(
                  moment(
                    `${annoSel}-${meseSel}-${parseInt(colDef.field!, 10)}`,
                    'YYYY-MM-D',
                    true
                  ).format('YYYY-MM-DD'),
                  value,
                  data.fruizione
                ),
            },
          }
        }),
      ],
    },
  ]

  const getAlunniOk = () => {
    const alunniOk =
      presenzeMensili &&
      presenzeMensili.map((presenzeMensili: any) => {
        const numeroPresenze = presenzeMensili.presenze.reduce((acc: any, giorno: any) => {
          if (isPresente(giorno.tipo)) {
            return acc + 1
          }
          return acc
        }, 0)

        const numeroAssenze = presenzeMensili.presenze.reduce((acc: any, giorno: any) => {
          if (isAssente(giorno.tipo)) {
            return acc + 1
          }
          return acc
        }, 0)

        const presenze: {
          [key: string]: TipoPresenza
        } = presenzeMensili.presenze.reduce((acc: any, g: any) => {
          const numeroGiorno = moment(g.data).date()
          return {
            ...acc,
            [numeroGiorno + '']: g.tipo,
          }
        }, {})

        const numeroDimenticanze = giorniDelMese.reduce((acc, g) => {
          const data = moment(`${annoSel}-${meseSel}-${g}`, 'YYYY-MM-D', true).format('YYYY-MM-DD')
          const isDimenticato = isErroreDimenticato(
            presenze[g + ''],
            giorniNonErogazione,
            presenzeMensili.fruizione,
            data
          )
          return acc + (isDimenticato ? 1 : 0)
        }, 0)

        const numeroSalvataggiErrati = presenzeMensili.presenze.reduce((acc: any, g: any) => {
          const isSegnatoSbagliato = isErroreSegnato(
            g.tipo,
            giorniNonErogazione,
            presenzeMensili.fruizione,
            moment(g.data, 'YYYY-MM-D', true).format('YYYY-MM-DD')
          )
          return acc + (isSegnatoSbagliato ? 1 : 0)
        }, 0)

        const numeroErrori = numeroDimenticanze + numeroSalvataggiErrati

        const numeroWarning = presenzeMensili.presenze.reduce((acc: any, g: any) => {
          const data = moment(g.data, 'YYYY-MM-DD', true).format('YYYY-MM-DD')
          const warning = isWarning(data, g.tipo, presenzeMensili.fruizione)
          return acc + (warning ? 1 : 0)
        }, 0)

        const hasAnomalie = alunniDaControllare.find(
          (alunno: any) => alunno.id === presenzeMensili.id
        )

        const alunnoConAnomalieFruizione = alunniConAnomalieFruizioni.find(
          (alunno: any) => alunno.alunnoId === presenzeMensili.id
        )

        const hasAnomalieFruizione =
          alunnoConAnomalieFruizione && hasAnomalieFruizioni(alunnoConAnomalieFruizione, servizioId)

        const totaleDaFormattare = presenzeMensili.totaleRetta || 0
        const totale = `€ ${totaleDaFormattare.toFixed(2)}`.replace('.', ',')

        return {
          ...presenzeMensili,
          sezione: `${presenzeMensili.sezione.classe} ${presenzeMensili.sezione.sezione}`,
          totale,
          numeroPresenze,
          numeroAssenze,
          numeroErrori,
          numeroWarning,
          hasAnomalie: !!hasAnomalie,
          hasAnomalieFruizione: !!hasAnomalieFruizione,
          ...presenze,
        }
      })

    return alunniOk
  }

  const getNumeroErrori = (alunniOk: any) => {
    return alunniOk.reduce(
      (acc: any, alunno: any) => {
        return {
          numeroErrori: acc.numeroErrori + alunno.numeroErrori,
          numeroWarning: acc.numeroWarning + alunno.numeroWarning,
          numeroAnomalie: acc.numeroAnomalie + (alunno.hasAnomalie ? 1 : 0),
          numeroAnomalieFruizione:
            acc.numeroAnomalieFruizione + (alunno.hasAnomalieFruizione ? 1 : 0),
        }
      },
      {
        numeroErrori: 0,
        numeroWarning: 0,
        numeroAnomalie: 0,
        numeroAnomalieFruizione: 0,
      }
    )
  }

  useEffect(() => {
    setAlunni(getAlunniOk())
  }, [isFetchingPresenze])

  useEffect(() => {
    setNumeroErrori(getNumeroErrori(alunni))
  }, [alunni])

  // ========================
  // MUTATIONS
  // ========================

  // UpdateAlunno
  const [generaAvvisiPagamento] = useGeneraAvvisiPagamento()

  const onSubmit = (data: any) => {
    const dataToPass = {
      ...data,
      scuolaId: scuolaSel,
      mese: parseInt(meseSel),
      anno: parseInt(annoSel),
    }

    generaAvvisiPagamento({
      servizioId,
      avviso: dataToPass,
    })
    setFormModalOpen(false)
  }

  // Generazione disabled
  const ciSonoErrori =
    numeroErrori.numeroErrori + numeroErrori.numeroAnomalie + numeroErrori.numeroAnomalieFruizione >
    0
  const generazioneInCorso = statoAvvisiPagamento && statoAvvisiPagamento.status === 'progress'
  const almenoUnoDaGenerare = alunni.some((e: any) => {
    return e.calcoloSbloccato || !(e.avviso || e.fruizione.pagamentoSospeso)
  })
  const generazioneDisabled = ciSonoErrori || generazioneInCorso || !almenoUnoDaGenerare

  return (
    <div className="bg-white py-6">
      <style>{`
        .ag-theme-alpine * {
          border-style: none;
        }
        .ag-react-container {
          height: 100%;
        }
      `}</style>
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-lg leading-tight mt-3 mb-4 text-gray-600 flex items-center">
          <InlineEdit
            type={InputType.Select}
            value={scuolaSel}
            onChange={(v) => setScuolaSel(v)}
            options={scuoleOptions}
            editClass="form-select text-lg"
            viewClass="cursor-pointer text-blue-500"
          />
        </h2>
        <h1 className="text-4xl leading-tight mb-8">
          <span className="text-xl">Presenze di</span>{' '}
          <InlineEdit
            type={InputType.Select}
            value={meseSel}
            onChange={(v) => {
              setMeseSel(v)
            }}
            options={mesiOptions}
            viewClass="cursor-pointer text-blue-500"
            editClass="form-select text-lg"
          />
        </h1>

        {numeroErrori.numeroErrori +
          numeroErrori.numeroWarning +
          numeroErrori.numeroAnomalie +
          numeroErrori.numeroAnomalieFruizione >
          0 && (
          <div className="bg-orange-100 py-3 px-6 rounded mb-6">
            <div className="flex flex-col">
              {!!numeroErrori.numeroErrori && (
                <div className="flex items-center">
                  <div className="w-5 h-5 flex justify-center items-center bg-red-700 text-xs text-white rounded-full mr-2">
                    {numeroErrori.numeroErrori}
                  </div>
                  <div>
                    Presenze non salvate o indicate in giorni di chiusura o con alunno ritirato
                  </div>
                </div>
              )}
              {!!numeroErrori.numeroWarning && (
                <div className="flex items-center">
                  <div className="w-5 h-5 flex justify-center items-center bg-yellow-500 text-xs text-white rounded-full mr-2">
                    {numeroErrori.numeroWarning}
                  </div>
                  <div>Presenze in giorni non previsti</div>
                </div>
              )}
              {!!numeroErrori.numeroAnomalie && (
                <div className="flex items-center">
                  <div className="w-5 h-5 flex justify-center items-center bg-teal-400 text-xs text-white rounded-full mr-2">
                    {numeroErrori.numeroAnomalie}
                  </div>
                  <div>Alunni con anomalie nei dati</div>
                </div>
              )}
              {!!numeroErrori.numeroAnomalieFruizione && (
                <div className="flex items-center">
                  <div className="w-5 h-5 flex justify-center items-center bg-purple-400 text-xs text-white rounded-full mr-2">
                    {numeroErrori.numeroAnomalieFruizione}
                  </div>
                  <div>Alunni con anomalie nelle fruizioni</div>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="flex bg-blue-100 py-3 px-6 rounded mb-6">
          <button
            disabled={generazioneDisabled}
            type="button"
            onClick={() => setFormModalOpen(true)}
            className={classNames(
              'block bg-blue-600 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150',
              {
                'opacity-50 cursor-not-allowed': generazioneDisabled,
              },
              {
                'hover:bg-blue-700 hover:shadow-xl': !generazioneDisabled,
              }
            )}
          >
            Generazione avvisi di pagamento
          </button>
          {statoAvvisiPagamento && statoAvvisiPagamento.status === 'done' && (
            <div className="ml-6 text-sm text-blue-600 leading-5 py-2">Avvisi già generati</div>
          )}
          {statoAvvisiPagamento && statoAvvisiPagamento.status === 'progress' && (
            <div className="ml-6 flex">
              <Loading size={30} type="Watch" />
              <div className="ml-3 text-sm text-blue-600 leading-5 py-2">
                Generazione in corso...
              </div>
            </div>
          )}
          {statoAvvisiPagamento && statoAvvisiPagamento.status === 'error' && (
            <div className="ml-6 text-sm text-red-600 leading-5 py-2">
              Errore durante l'ultima generazione
            </div>
          )}

          {/* {bolletteGenerate ? (
            <div className="flex">
              <button
                type="button"
                className="block bg-blue-600 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150 hover:bg-blue-700 hover:shadow-xl"
              >
                Scarica bollette
              </button>
              <button
                type="button"
                className="ml-3 block bg-purple-600 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150 hover:bg-purple-700 hover:shadow-xl"
              >
                Scarica file SDD
              </button>
            </div>
          ) : (
            <button
              disabled={
                numeroErrori.numeroErrori +
                  numeroErrori.numeroAnomalie +
                  numeroErrori.numeroAnomalieFruizione >
                0
              }
              type="button"
              onClick={handleGeneraBollette}
              className={classNames(
                'block bg-blue-600 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150',
                {
                  'opacity-50 cursor-not-allowed': numeroErrori.numeroErrori > 0,
                },
                {
                  'hover:bg-blue-700 hover:shadow-xl': !numeroErrori.numeroErrori,
                }
              )}
            >
              Genera bollette
            </button>
          )} */}
        </div>
        <div
          className="ag-theme-alpine"
          style={{
            height: '500px',
            width: '960px',
          }}
        >
          {!!alunni && alunni.length > 0 ? (
            <AgGridReact
              columnDefs={columnDefs}
              rowData={alunni}
              getRowStyle={(params: any) => {
                if (params.data.fruizione.pagamentoSospeso) {
                  return { color: '#aaa' }
                }
              }}
            ></AgGridReact>
          ) : (
            <p>Non ci sono dati da mostrare</p>
          )}
        </div>
      </div>

      <ReactModal
        isOpen={formModalOpen}
        onRequestClose={() => {
          setFormModalOpen(false)
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        style={modalCustomStyles}
      >
        <div className="max-w-lg p-8">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex justify-between items-center"
            autoComplete="off"
          >
            <div className="flex flex-col">
              <div className="flex mb-3">
                <FormField
                  fieldName="dataEmissione"
                  label="Data di emissione"
                  type={formFieldInputType.Date}
                  validation={{ required: 'Inserisci la data di emissione' }}
                  register={register}
                  errors={errors}
                  className="mr-6"
                />
                <FormField
                  fieldName="dataScadenza"
                  label="Data di scadenza"
                  type={formFieldInputType.Date}
                  validation={{ required: 'Inserisci la data di scadenza' }}
                  register={register}
                  errors={errors}
                />
              </div>
              <FormField
                fieldName="periodo"
                label="Periodo"
                type={formFieldInputType.Text}
                validation={{ required: 'Inserisci il periodo' }}
                register={register}
                errors={errors}
                className="mb-3"
              />
              <FormField
                fieldName="nota"
                label="Note"
                type={formFieldInputType.Textarea}
                rows={3}
                validation={{ maxLength: { value: 100, message: 'Max 100 caratteri' } }}
                register={register}
                errors={errors}
                className="mb-3"
              />
              <button
                type="submit"
                className="block bg-blue-600 focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150 hover:bg-blue-700 hover:shadow-xl"
              >
                Genera avvisi di pagamento
              </button>
            </div>
          </form>
        </div>
      </ReactModal>

      <ReactModal
        isOpen={modalBollettaOpen}
        onRequestClose={() => {
          setFruizioneId(0)
          // setBolletta(null)
          setModalBollettaOpen(false)
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        style={modalCustomStyles}
      >
        {!!bollettaMese && bollettaMese.righe && (
          <div className="max-w-lg">
            <div className="bg-blue-600 text-white font-semibold px-6 py-2 text-center">
              Bolletta provvisoria di {moment(bollettaMese?.mese, 'M', true).format('MMMM')}{' '}
              {bollettaMese?.anno}
              <br />
              {alunnoBolletta?.cognome} {alunnoBolletta?.nome}
            </div>

            <div className="px-8 py-6">
              <table className="mx-auto">
                <tbody>
                  {bollettaMese?.righe.map((riga) => (
                    <tr key={riga.descrizione}>
                      <td className="pb-1 px-4">{riga.descrizione}</td>
                      <td className="pb-1 px-4 text-right">{riga.importo}</td>
                    </tr>
                  ))}
                  <tr className="border-t border-blue-300">
                    <td className="pt-2 px-4 uppercase font-bold">Totale</td>
                    <td className="pt-2 px-4 text-right font-bold">{bollettaMese?.totale}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </ReactModal>

      <ReactModal
        isOpen={modalAvvisoOpen}
        onRequestClose={() => {
          setAvvisoId(0)
          // setBolletta(null)
          setModalAvvisoOpen(false)
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        style={modalCustomStyles}
      >
        {righeAvvisoModale.length > 0 && (
          <div className="max-w-lg">
            <div className="bg-blue-600 text-white font-semibold px-6 py-2 text-center">
              Bolletta di {moment(avvisoPagamento?.mese, 'M', true).format('MMMM')}{' '}
              {avvisoPagamento?.anno}
              <br />
              {avvisoPagamento?.bambinoCognome} {avvisoPagamento?.bambinoNome}
            </div>

            <div className="px-8 py-6">
              <table className="mx-auto">
                <tbody>
                  {righeAvvisoModale.map((riga: RigaBolletta) => (
                    <tr key={riga.descrizione}>
                      <td className="pb-1 px-4">{riga.descrizione}</td>
                      <td className="pb-1 px-4 text-right">{riga.importo}</td>
                    </tr>
                  ))}
                  <tr className="border-t border-blue-300">
                    <td className="pt-2 px-4 uppercase font-bold">Totale</td>
                    <td className="pt-2 px-4 text-right font-bold">
                      {avvisoPagamento?.importoTotale}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </ReactModal>
    </div>
  )
}

export default PresenzeMensili
