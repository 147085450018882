import { useMutation, queryCache } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

interface CreateSezione {
  annoScolastico: string
  scuolaId: number
  classe: string
  sezione: string
}

const createSezione = async (sezione: CreateSezione) => {
  const { data } = await axios.post(`/sezioni/`, sezione)
  return data
}

const useCreateSezione = () => {
  return useMutation(
    (sezione: CreateSezione) => {
      return createSezione(sezione)
    },
    {
      onSuccess: (data: any) => {
        queryCache.refetchQueries('sezioniByScuolaId', { force: true })
        cogoToast.success('Nuova sezione creata')
      },
      onError: () => {},
    }
  )
}

export default useCreateSezione
