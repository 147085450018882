import React from 'react'
import classNames from 'classnames'
import FormField, { InputType } from '../../../../components/FormField'

interface FormSingoloServizioProps {
  register: any
  errors: any
  control?: any
  watch?: any
  intestatari: any[]
}

const ComuneBergamoMensa: React.FC<FormSingoloServizioProps> = ({
  register,
  errors,
  control,
  watch,
  intestatari,
}) => {
  return (
    <div>
      <section>
        {/* <h1 className="text-2xl mb-2 leading-tight">Mensa</h1>
        <hr className="border-blue-200 border-t-2 my-8"></hr> */}
        <div className="text-gray-900 mb-5 font-semibold leading-none">Intestatario bolletta</div>
        <FormField
          fieldName="intestatario"
          type={InputType.Select}
          options={intestatari.map((intestatario: any) => ({
            value: intestatario.id,
            label: `${intestatario.nome} ${intestatario.cognome}`,
          }))}
          control={control}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <div className="text-gray-900 mt-6 mb-5 font-semibold leading-none">Pagamento</div>
        <FormField
          fieldName="pagamentoSdd"
          label="Pagamento SDD"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="codiceSdd"
          label="Codice SDD"
          type={InputType.Text}
          register={register}
          errors={errors}
          className="mb-3"
        />

        <FormField
          fieldName="pagamentoSospeso"
          label="Bollette sospese"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">
          Giorni di pasto previsti
        </div>
        <div className="flex items-end mb-4">
          <FormField
            fieldName="lun"
            label="L"
            type={InputType.WeekDay}
            register={register}
            errors={errors}
          />
          <FormField
            fieldName="mar"
            label="M"
            type={InputType.WeekDay}
            register={register}
            errors={errors}
          />
          <FormField
            fieldName="mer"
            label="M"
            type={InputType.WeekDay}
            register={register}
            errors={errors}
          />
          <FormField
            fieldName="gio"
            label="G"
            type={InputType.WeekDay}
            register={register}
            errors={errors}
          />
          <FormField
            fieldName="ven"
            label="V"
            type={InputType.WeekDay}
            register={register}
            errors={errors}
          />
          <div className="leading-none text-xs text-blue-600 uppercase">Ufficiale</div>
        </div>
        <div className="flex items-end">
          <FormField
            fieldName="lunBidella"
            label="L"
            type={InputType.WeekDay}
            inputClassName="text-pink-600"
            register={register}
            errors={errors}
          />
          <FormField
            fieldName="marBidella"
            label="M"
            type={InputType.WeekDay}
            inputClassName="text-pink-600"
            register={register}
            errors={errors}
          />
          <FormField
            fieldName="merBidella"
            label="M"
            type={InputType.WeekDay}
            inputClassName="text-pink-600"
            register={register}
            errors={errors}
          />
          <FormField
            fieldName="gioBidella"
            label="G"
            type={InputType.WeekDay}
            inputClassName="text-pink-600"
            register={register}
            errors={errors}
          />
          <FormField
            fieldName="venBidella"
            label="V"
            type={InputType.WeekDay}
            inputClassName="text-pink-600"
            register={register}
            errors={errors}
          />
          <div className="leading-none text-xs text-pink-600 uppercase">Da operatore</div>
        </div>
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">Agevolazioni</div>
        <FormField
          fieldName="iseeMin5000"
          label="ISEE < 5000"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="secondoFratello"
          label="Secondo fratello"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="handicap"
          label="Portatore di handicap"
          type={InputType.Boolean}
          register={register}
          errors={errors}
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">
          Date inizio ed eventuale ritiro
        </div>
        <FormField
          fieldName="dataInizio"
          label="Inizio servizio"
          type={InputType.Date}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="dataRitiro"
          label="Data ritiro"
          type={InputType.Date}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="dataRitiroBidella"
          label="Data ritiro operatore"
          type={InputType.Date}
          register={register}
          errors={errors}
          className="mb-3"
          labelClassName="text-pink-600"
          inputClassName="text-pink-600"
        />
        <FormField
          fieldName="isRitiroConRestituzioneQuota"
          label="Restituzione quota iscrizione"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">
          Rimborsi e Maggiorazioni
        </div>
        <FormField
          fieldName="rimborsoMaggiorazioneUnaTantumImporto"
          label="Importo una tantum Prossima bolletta"
          type={InputType.Number}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="rimborsoMaggiorazioneUnaTantumDescrizione"
          label="Descrizione causale una tantum"
          type={InputType.Text}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="rimborsoMaggiorazioneMensileImporto"
          label="Importo mensile"
          type={InputType.Number}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="rimborsoMaggiorazioneMensileDescrizione"
          label="Descrizione causale mensile"
          type={InputType.Text}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="riportoPrecedente"
          label="Riporto precedente"
          type={InputType.Number}
          register={register}
          errors={errors}
          className="mb-3"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">Note</div>
        <FormField
          fieldName="note"
          label="Note interne"
          type={InputType.Textarea}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="noteBidella"
          label="Note operatore"
          type={InputType.Textarea}
          register={register}
          errors={errors}
          className="mb-3"
          labelClassName={classNames({ 'text-pink-500': watch('noteBidella') })}
          inputClassName={classNames({ 'text-pink-500': watch('noteBidella') })}
        />
        <FormField
          fieldName="sezioneIdBidella"
          label="Sezione indicata da operatore"
          type={InputType.Select}
          register={register}
          control={control}
          errors={errors}
          isClearable
          className="mb-3"
          labelClassName={classNames({ 'text-pink-500': watch('sezioneIdBidella') })}
          inputStyle={watch('sezioneIdBidella') ? { color: '#ed64a6' } : undefined}
        />
        <FormField
          fieldName="modificatoBidella"
          label="Modificato da operatore"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
          labelClassName={classNames({ 'text-pink-500': watch('modificatoBidella') })}
          inputClassName={classNames({ 'text-pink-500': watch('modificatoBidella') })}
        />
      </section>
      <button
        type="submit"
        className="mt-8 block bg-blue-600 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
      >
        Salva
      </button>
    </div>
  )
}

export default ComuneBergamoMensa
