import { useMutation, queryCache } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

interface ICreateIntestatario {
  intestatario?: any
  alunnoId?: number
}

const createIntestatario = async ({
  intestatario,
  alunnoId,
}: ICreateIntestatario) => {
  const { data } = await axios.post(
    `/alunni/${alunnoId}/intestatario/`,
    intestatario
  )
  return data
}

const useCreateIntestatario = (alunnoId?: number) => {
  return useMutation(
    ({ intestatario }: { intestatario: any }) => {
      const intestatarioToCreate = {
        ...intestatario,
      }
      return createIntestatario({
        intestatario: intestatarioToCreate,
        alunnoId,
      })
    },
    {
      onSuccess: (res: any) => {
        queryCache.refetchQueries('alunnoByIdByAnno')
        cogoToast.success('Intestatario creato')
      },
      onError: () => {},
    }
  )
}

export default useCreateIntestatario
