import { useQuery } from 'react-query'
import axios from 'axios'

interface IAlunniDaControllare {
  annoScolastico: string
  scuolaId?: number
}

const getCountAlunniDaControllare = async (
  _: string,
  { annoScolastico, scuolaId }: IAlunniDaControllare
) => {
  const scuolaIdQuery = scuolaId ? `&scuolaId=${scuolaId}` : ''
  const { data } = await axios.get(
    `/alunni/da-controllare?limit=1&annoScolastico=${annoScolastico}${scuolaIdQuery}`
  )
  return data.count
}

const useCountAlunniDaControllare = ({
  annoScolastico,
  scuolaId,
}: IAlunniDaControllare) => {
  return useQuery(
    !!annoScolastico && ['countAlunniDaControllare', { annoScolastico, scuolaId }],
    getCountAlunniDaControllare,
    {
      // 5 minuti
      staleTime: 5 * 60 * 1000,
    }
  )
}

export default useCountAlunniDaControllare
