import React, { useContext } from 'react'
import { RouteComponentProps, Link } from '@reach/router'

import AnnoScolasticoContext from '../../../context/AnnoScolasticoContext'
import ServizioContext from '../../../context/ServizioContext'
import useFruizioniDaControllare from '../../../apiHooks/queries/useFruizioniDaControllare'
import Loading from '../../../components/Loading'
import { isAnomalia } from '../../../lib/controlloAnomalie'

interface AlunnoDaControllare {
  id: number
  alunnoId: number
  cognome: string
  nome: string
  intestatarioId: number | null
  dataInizio: string | null
  dataFineAmbientamento: string | null
  tipoFrequenza: string | null
  isAsiloGratis: boolean
  asiloGratisQuotaGenitore: number | null
  pastiSettimanaliPrevisti: boolean | null
  pagamentoSdd: boolean
  codiceSdd: number | null
  modificatoBidella: boolean
  sezioneIdBidella: number | null
}

const DatoMancante = () => (
  <div className="flex justify-center items-center">
    <div className="bg-red-600 text-white text-sm font-bold rounded-full leading-none w-4 h-4 flex justify-center items-center">
      ?
    </div>
  </div>
)

const DatoWarning = () => (
  <div className="flex justify-center items-center">
    <div className="bg-pink-500 text-white text-sm font-bold rounded-full leading-none w-4 h-4 flex justify-center items-center">
      !
    </div>
  </div>
)

const DatoOk = () => (
  <div className="flex justify-center items-center">
    <div className="bg-green-500 text-white text-sm font-bold rounded-full leading-none w-4 h-4 flex justify-center items-center">
      ✓
    </div>
  </div>
)

const FruizioniDaControllare: React.FC<RouteComponentProps> = () => {
  //===================
  // CONTEXT
  //===================
  const { annoScolastico } = useContext(AnnoScolasticoContext)
  const { servizioId } = useContext(ServizioContext)

  //===================
  // HOOKS QUERIES
  //===================
  const { data: alunni = [], isFetching } = useFruizioniDaControllare({
    annoScolastico,
    servizioId,
  })

  return (
    <div className="pt-6 pb-20 min-h-screen bg-white">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        {isFetching && <Loading className="py-12" />}

        <table>
          <thead>
            <tr>
              <th className="px-4 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Alunno
              </th>
              <th className="px-4 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Intest.
              </th>
              {servizioId === 2 && (
                <>
                  <th className="px-4 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Inizio
                  </th>
                  <th className="px-4 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Fine amb.
                  </th>
                  <th className="px-4 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Tipo freq.
                  </th>
                  <th className="px-4 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Asilo gratis
                  </th>
                </>
              )}
              {servizioId === 1 && (
                <th className="px-4 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Giorni pasti
                </th>
              )}
              <th className="px-4 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                SDD
              </th>
              <th className="px-4 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Mod. op.
              </th>
              <th className="px-4 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                Mod. sez. op.
              </th>
              {/* <th className="px-4 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th> */}
            </tr>
          </thead>
          <tbody>
            {alunni.map((alunno: AlunnoDaControllare) => (
              <tr key={alunno.alunnoId}>
                <td className="px-4 py-4 whitespace-no-wrap border-b border-gray-200">
                  <Link
                    to={`/app/anagrafica/alunno/${alunno.alunnoId}/servizio/${servizioId}`}
                    className="border border-white hover:border-blue-200 hover:bg-blue-100 focus:shadow-outline focus:outline-none text-blue-500 leading-5 py-2 px-5 rounded transition-colors duration-150"
                  >
                    {alunno.cognome} {alunno.nome}
                  </Link>
                </td>
                <td className="px-4 py-4 whitespace-no-wrap border-b border-gray-200">
                  {isAnomalia('intestatarioId', alunno.intestatarioId) ? (
                    <DatoMancante />
                  ) : (
                    <DatoOk />
                  )}
                </td>
                {servizioId === 2 && (
                  <>
                    <td className="px-4 py-4 whitespace-no-wrap border-b border-gray-200">
                      {isAnomalia('dataInizio', alunno.dataInizio) ? (
                        <DatoMancante />
                      ) : (
                        <DatoOk />
                      )}
                    </td>
                    <td className="px-4 py-4 whitespace-no-wrap border-b border-gray-200">
                      {isAnomalia(
                        'dataFineAmbientamento',
                        alunno.dataFineAmbientamento
                      ) ? (
                        <DatoMancante />
                      ) : (
                        <DatoOk />
                      )}
                    </td>
                    <td className="px-4 py-4 whitespace-no-wrap border-b border-gray-200">
                      {isAnomalia('tipoFrequenza', alunno.tipoFrequenza) ? (
                        <DatoMancante />
                      ) : (
                        <DatoOk />
                      )}
                    </td>
                    <td className="px-4 py-4 whitespace-no-wrap border-b border-gray-200">
                      {isAnomalia(
                        'asiloGratis',
                        alunno.isAsiloGratis,
                        alunno.asiloGratisQuotaGenitore
                      ) ? (
                        <DatoMancante />
                      ) : (
                        <DatoOk />
                      )}
                    </td>
                  </>
                )}
                {servizioId === 1 && (
                  <td className="px-4 py-4 whitespace-no-wrap border-b border-gray-200">
                    {isAnomalia(
                      'pastiSettimanaliPrevisti',
                      alunno.pastiSettimanaliPrevisti
                    ) ? (
                      <DatoMancante />
                    ) : (
                      <DatoOk />
                    )}
                  </td>
                )}
                <td className="px-4 py-4 whitespace-no-wrap border-b border-gray-200">
                  {isAnomalia('SDD', alunno.pagamentoSdd, alunno.codiceSdd) ? (
                    <DatoMancante />
                  ) : (
                    <DatoOk />
                  )}
                </td>
                <td className="px-4 py-4 whitespace-no-wrap border-b border-gray-200">
                  {isAnomalia('modificatoBidella', alunno.modificatoBidella) ? (
                    <DatoWarning />
                  ) : (
                    <DatoOk />
                  )}
                </td>
                <td className="px-4 py-4 whitespace-no-wrap border-b border-gray-200">
                  {isAnomalia('sezioneIdBidella', alunno.sezioneIdBidella) ? (
                    <DatoWarning />
                  ) : (
                    <DatoOk />
                  )}
                </td>
                {/* <td className="px-4 py-4 whitespace-no-wrap border-b border-gray-200">
                  <Link
                    to={`/app/anagrafica/alunno/${alunno.id}/servizio/${servizioId}`}
                    className="border border-blue-200 hover:bg-blue-500 hover:border-blue-500 focus:shadow-outline focus:outline-none text-blue-500 hover:text-white leading-5 py-2 px-5 rounded transition-colors duration-150"
                  >
                    Controlla
                  </Link>
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default FruizioniDaControllare
