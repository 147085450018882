import React, { useState, useContext, useEffect } from 'react'
import { RouteComponentProps, Link } from '@reach/router'
import InlineEdit, { InputType } from 'riec'
import classNames from 'classnames'
import moment from 'moment'
import ReactModal from 'react-modal'
import { FiEdit } from 'react-icons/fi'
import CreatableSelect from 'react-select/creatable'
import { useForm } from 'react-hook-form'
import DayPickerInput from 'react-day-picker/DayPickerInput'

import ServizioContext from '../../context/ServizioContext'
import AnnoScolasticoContext from '../../context/AnnoScolasticoContext'

import useScuoleByServizioId from '../../apiHooks/queries/useScuoleByServizioId'
import usePresenzeSezioniData from '../../apiHooks/queries/usePresenzeSezioniData'
import usePresenzeAlunniData from '../../apiHooks/queries/usePresenzeAlunniData'
import useAlunniAltreSezioni from '../../apiHooks/queries/useAlunniAltreSezioni'

import useUpdatePresenzeGiorno from '../../apiHooks/mutations/useUpdatePresenzeGiorno'
import useUpdateFruizione from '../../apiHooks/mutations/useUpdateFruizione'
import useCreateAlunno from '../../apiHooks/mutations/useCreateAlunno'
import useUpdateAlunno from '../../apiHooks/mutations/useUpdateAlunno'

import Loading from '../../components/Loading'

import FormField, { InputType as FormInputType } from '../../components/FormField'

import reactSelectCustomStyles from '../../components/reactSelectCustomStyles'
import 'react-day-picker/lib/style.css'

import MomentLocaleUtils from 'react-day-picker/moment'

import 'moment/locale/it'
import useGiorniNonErogazioneByServizioId from '../../apiHooks/queries/useGiorniNonErogazioneByServizioId'
import { isNonErogazione } from '../../lib/validazionePresenze'
import { DayModifiers } from 'react-day-picker'

ReactModal.setAppElement('#root')

const MONTHS = [
  'Gennaio',
  'Febbraio',
  'Marzo',
  'Aprile',
  'Maggio',
  'Giugno',
  'Luglio',
  'Agosto',
  'Settembre',
  'Ottobre',
  'Novembre',
  'Dicembre',
]
const WEEKDAYS_LONG = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato']
const WEEKDAYS_SHORT = ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa']

const modifiersStyles = {
  p: {
    color: '#48bb78',
  },
  a: {
    color: '#e53e3e',
  },
  b: {
    color: '#48bb78',
  },
  m: {
    color: '#48bb78',
  },
  nonErogazione: {
    backgroundColor: '#f0f0f0',
  },
  // errore: {
  //   //border: '2px solid #feb2b2',
  // },
  // warning: {
  //   border: '2px solid #fc0',
  //   // backgroundColor: '#fc0',
  // },
  outside: {
    border: 'none!important',
  },
  disabled: {
    backgroundColor: 'transparent!important',
  },
}

const modalCustomStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    padding: 0,
    border: 'none',
  },
  overlay: {
    backgroundColor: 'hsla(207, 40%, 60%, 0.7)',
  },
}

interface Scuola {
  id: number
  nome: string
}

interface Sezione {
  id: number
  presenze: boolean
  classe: string
  sezione: string
}

type Giorno = 'lunBidella' | 'marBidella' | 'merBidella' | 'gioBidella' | 'venBidella'

interface Fruizione {
  id: number
  lunBidella: boolean
  marBidella: boolean
  merBidella: boolean
  gioBidella: boolean
  venBidella: boolean
  noteBidella: string
}

type Presenza = 'P' | 'A' | 'B' | 'M'

interface Alunno {
  id: number
  nome: string
  cognome: string
  fruizione: Fruizione
  presenza: string
}

interface Totali {
  P: number
  A: number
  B: number
  M: number
}

const totaliInitial = { P: 0, A: 0, B: 0, M: 0 }

const calcolaTotali = (alunni: Alunno[]): Totali => {
  const totali = alunni.reduce((acc: Totali, alunno: Alunno) => {
    const presenza = alunno.presenza as Presenza
    const presenze = { ...acc }
    presenze[presenza] = presenze[presenza] + 1
    return presenze
  }, totaliInitial)
  return totali
}

const PresenzeGiornaliere: React.FC<RouteComponentProps> = () => {
  //===================
  // CONTEXT
  //===================
  const { servizioId } = useContext(ServizioContext)
  const { annoScolastico } = useContext(AnnoScolasticoContext)

  // DATE PER CALENDARIO
  const anni: string[] = annoScolastico.split('-')
  const dataMin = moment(`${anni[0]}-09-01`, 'YYYY-MM-DD', true)
  const dataMax = moment(`${anni[1]}-08-31`, 'YYYY-MM-DD', true)
  const todayMoment = moment()
  const dataInitial = todayMoment.isBefore(dataMin)
    ? dataMin.toDate()
    : todayMoment.isAfter(dataMax)
    ? dataMax.toDate()
    : todayMoment.toDate()

  //===================
  // LOCAL STATE
  //===================
  const [cercaAlunno, setCercaAlunno] = useState('')
  const [mostraRitirati, setMostraRitirati] = useState<boolean>(false)
  const [alunnoSel, setAlunnoSel] = useState<Alunno | null>(null)
  const [alunni, setAlunni] = useState<Alunno[]>([])
  const [totali, setTotali] = useState<Totali>(totaliInitial)
  const [dataSel, setDataSel] = useState<string>(moment(dataInitial).format('YYYY-MM-DD'))

  //===================
  // HOOKS QUERIES
  //===================
  // Fetch giorni non erogazione
  const { data: giorniNonErogazione = [] } = useGiorniNonErogazioneByServizioId({
    servizioId: servizioId,
    from: moment(dataMin).format('YYYY-MM-DD'),
    to: moment(dataMax).format('YYYY-MM-DD'),
  })

  // Fetch scuole
  const { data: scuole = [], isFetching: isFetchingScuole } = useScuoleByServizioId(servizioId)
  const [scuolaSel, setScuolaSel] = useState<Scuola | null>(null)

  // Fetch sezioni
  const { data: sezioni = [], isFetching: isFetchingSezioni } = usePresenzeSezioniData({
    servizioId,
    scuolaId: scuolaSel?.id,
    dataPresenze: dataSel,
  })
  const [sezioneSel, setSezioneSel] = useState<Sezione | null>(null)

  // Fetch alunni
  const { data: presenzeAlunni = [], isFetching: isFetchingPresenzeAlunni } = usePresenzeAlunniData(
    {
      servizioId,
      sezioneId: sezioneSel?.id,
      dataPresenze: dataSel,
      mostraRitirati,
    }
  )

  // Fetch alunni altre sezioni
  const {
    data: alunniAltreSezioni = [],
    isFetching: fetchingAlunniAltreSezioni,
  } = useAlunniAltreSezioni({
    servizioId,
    sezioneId: sezioneSel?.id,
    dataPresenze: dataSel,
    cognome: cercaAlunno,
  })

  //===================
  // HOOKS MUTATIONS
  //===================
  const [updatePresenze] = useUpdatePresenzeGiorno(servizioId)
  const [updateFruizione] = useUpdateFruizione()
  const [createAlunno] = useCreateAlunno()
  const [updateAlunno] = useUpdateAlunno()

  //===================
  // HOOK FORM
  //===================
  const { register, handleSubmit, errors, reset } = useForm({
    defaultValues: alunnoSel ? alunnoSel.fruizione : {},
  })

  //===================
  // EFFECTS
  //===================

  // Set prima scuola
  useEffect(() => {
    if (scuole.length > 0) {
      setScuolaSel(scuole[0])
    }
  }, [scuole])

  // Set prima sezione
  useEffect(() => {
    if (sezioni.length > 0) {
      setSezioneSel(sezioni[0])
    }
  }, [sezioni])

  // Set alunni
  useEffect(() => {
    const alunni = presenzeAlunni.map((alunno: Alunno) => {
      let presenza = alunno.presenza
      if (!presenza) {
        const giorno = `${moment(dataSel, 'YYYY-MM-DD', true)
          .format('ddd')
          .toLowerCase()}Bidella` as Giorno

        presenza = alunno.fruizione[giorno] ? 'P' : 'A'
      }
      return {
        ...alunno,
        presenza,
      }
    })

    setAlunni(alunni)
  }, [isFetchingPresenzeAlunni, dataSel])

  // Aggiorna totali
  useEffect(() => {
    setTotali(calcolaTotali(alunni))
  }, [alunni])

  //===================
  // EVENT HANDLERS
  //===================

  const handleChangePresenza = (alunnoId: number, presenza: string) => {
    const nuoviAlunni = alunni.map((alunno: Alunno) => {
      if (alunno.id === alunnoId) {
        return {
          ...alunno,
          presenza,
        }
      }
      return alunno
    })

    setAlunni(nuoviAlunni)
  }

  const handleSettaTutti = (tipoPresenza: Presenza) => () => {
    const nuoviAlunni = alunni.map((alunno: Alunno) => ({
      ...alunno,
      presenza: tipoPresenza,
    }))

    setAlunni(nuoviAlunni)
  }

  const openModal = (alunno: Alunno) => {
    document.getElementById('root')!.style.filter = 'blur(5px)'
    setAlunnoSel(alunno)
  }

  const closeModal = () => {
    document.getElementById('root')!.style.filter = 'blur(0)'
    setAlunnoSel(null)
  }

  const handleModificaFruizione = (datiFruizione: any) => {
    if (alunnoSel) {
      updateFruizione({
        fruizioneId: alunnoSel.fruizione.id,
        fruizione: {
          ...datiFruizione,
          dataRitiroBidella: datiFruizione.dataRitiroBidella || null,
          modificatoBidella: true,
        },
      })
    }
    closeModal()
  }

  const handleSalva = (event: React.FormEvent) => {
    event.preventDefault()
    if (sezioneSel) {
      updatePresenze({
        sezioneId: sezioneSel.id,
        dataPresenze: dataSel,
        presenze: alunni.map((alunno) => ({
          alunnoId: alunno.id,
          tipo: alunno.presenza,
        })),
      })
    }
  }

  // const handleCreaAlunno = (cognome: any) => {
  //   console.log('CREA', cognome)
  //   createAlunno({ annoScolastico, sezioneId: sezioneSel!.id, cognome, nome:''  })
  // }

  const handleChangeNuovoAlunno = (option: any) => {
    if (!option) {
      return
    }
    if (option.__isNew__) {
      createAlunno({
        servizioId,
        alunno: {
          annoScolastico,
          sezioneId: sezioneSel!.id,
          cognome: option.value,
        },
      })
    } else {
      // Spostamento verso questa sezione
      // updateAlunno({
      //   alunnoId: option.value,
      //   alunno: {
      //     sezioneId: sezioneSel?.id,
      //   },
      // })
      // Spostamento verso questa sezione
      updateFruizione({
        fruizioneId: option.value,
        fruizione: {
          sezioneIdBidella: sezioneSel?.id,
        },
      })
    }
  }

  const handleDayChange = (day: Date, modifiers: DayModifiers, dayPickerInput: DayPickerInput) => {
    // const input = dayPickerInput.getInput()
    setDataSel(moment(day).format('YYYY-MM-DD'))
  }

  const isDisabled = isNonErogazione(giorniNonErogazione, dataSel) || moment(dataSel, 'YYYY-MM-DD', true).isBefore(dataMin) || moment(dataSel, 'YYYY-MM-DD', true).isAfter(dataMax) 

  return (
    <div className="bg-white py-6">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        {isFetchingScuole && <Loading size={40} className="py-6" />}
        <h2 className="text-lg leading-tight mt-3 mb-4 text-gray-600 flex items-center">
          {scuolaSel && (
            <InlineEdit
              type={InputType.Select}
              value={scuolaSel.id + ''}
              onChange={(v) => setScuolaSel(scuole.find((s: Scuola) => s.id === +v))}
              options={scuole}
              valueKey="id"
              labelKey="nome"
              editClass="form-select text-lg"
              viewClass="cursor-pointer text-blue-500"
            />
          )}
          <span className="mx-3 text-gray-400">&raquo;</span>
          <DayPickerInput
            formatDate={MomentLocaleUtils.formatDate}
            parseDate={MomentLocaleUtils.parseDate}
            format="LL"
            placeholder={`${MomentLocaleUtils.formatDate(
              moment(dataSel, 'YYYY-MM-DD', true).toDate(),
              'LL',
              'it'
            )}`}
            inputProps={{
              className: 'form-input text-blue-500 cursor-pointer border-0',
            }}
            dayPickerProps={{
              locale: 'it',
              localeUtils: MomentLocaleUtils,
              className: 'bg-white rounded py-2 px-2 shadow-lg border-gray-200',
              months: MONTHS,
              initialMonth: dataInitial,
              fromMonth: dataMin.toDate(),
              toMonth: dataMax.toDate(),
              weekdaysLong: WEEKDAYS_LONG,
              weekdaysShort: WEEKDAYS_SHORT,
              firstDayOfWeek: 1,
              modifiersStyles: modifiersStyles,
              modifiers: {
                disabled: [
                  { daysOfWeek: [0, 6] },
                  (d: Date) => isNonErogazione(giorniNonErogazione, moment(d).format('YYYY-MM-DD')),
                  { before: dataMin.toDate() },
                  { after: dataMax.toDate() },
                ],
              },
              disabledDays: [
                { daysOfWeek: [0, 6] },
                (d: Date) => isNonErogazione(giorniNonErogazione, moment(d).format('YYYY-MM-DD')),
                { before: dataMin.toDate() },
                { after: dataMax.toDate() },
              ],
            }}
            value={moment(dataSel).format('DD MMMM YYYY')}
            onDayChange={handleDayChange}
          />
        </h2>

        <div className="flex flex-wrap items-center py-3 px-4 sm:px-6 bg-gray-100 mb-4">
          {isFetchingSezioni && <Loading size={40} className="py-6" />}
          {sezioni.map((sezione: any) => (
            <div
              key={sezione.id}
              className={classNames(
                'py-px px-2 rounded mr-2 my-1 text-sm font-semibold cursor-pointer',
                {
                  'bg-gray-300 hover:bg-gray-400 text-gray-700 hover:text-gray-900': !sezione.presenze,
                },
                {
                  'bg-green-300 hover:bg-green-400 text-green-800 hover:text-green-900':
                    sezione.presenze,
                },
                {
                  'border-4 border-blue-400 shadow-lg': sezione.id === sezioneSel?.id,
                }
              )}
              onClick={() => setSezioneSel(sezione)}
            >
              {sezione.classe} {sezione.sezione}
            </div>
          ))}
        </div>

        {isFetchingPresenzeAlunni && <Loading className="py-12" />}

        {!!isDisabled &&
          <div className="my-12 py-2 px-4 bg-orange-100 rounded border border-orange-200 text-lg">Non è possibile indicare presenze in un giorno di chiusura.</div>
        }

        {sezioneSel && !isDisabled && (
          <table>
            <thead>
              <tr>
                <th className="pl-4 sm:pl-6 align-bottom py-4">
                  <label className="flex items-center font-normal text-gray-600">
                    <input
                      type="checkbox"
                      className="form-checkbox"
                      checked={mostraRitirati}
                      onChange={(event) => {
                        setMostraRitirati(event.target.checked)
                      }}
                    />
                    <span className="ml-2">Mostra ritirati</span>
                  </label>
                </th>
                <th className="text-center align-bottom py-4">
                  <button
                    className="px-2 border border-gray-400 text-sm text-gray-600 rounded hover:bg-green-500 hover:text-white hover:border-green-500"
                    onClick={handleSettaTutti('P')}
                  >
                    Tutti presenti
                  </button>
                </th>
                <th className="text-center align-bottom py-4">
                  <button
                    className="px-2 border border-gray-400 text-sm text-gray-600 rounded hover:bg-red-500 hover:text-white hover:border-red-500"
                    onClick={handleSettaTutti('A')}
                  >
                    Tutti assenti
                  </button>
                </th>
                <th colSpan={3} className="py-4 text-right align-bottom"></th>
              </tr>
              <tr>
                <th
                  rowSpan={2}
                  className="px-4 sm:px-6 pt-2 pb-1 bg-gray-100 text-left text-2xl leading-4 font-semibold text-blue-500 uppercase"
                >
                  {sezioneSel.classe}
                  <sup className="lowercase">a</sup> {sezioneSel.sezione}
                </th>
                <th className="px-4 sm:px-6 pt-2 bg-gray-100 text-left text-sm leading-4 font-medium text-green-600 text-center">
                  P<span className="hidden sm:inline">resenti</span>
                </th>
                <th className="px-4 sm:px-6 pt-2 bg-gray-100 text-left text-sm leading-4 font-medium text-red-600 text-center">
                  A<span className="hidden sm:inline">ssenti</span>
                </th>
                {servizioId === 1 && (
                  <>
                    <th className="px-4 sm:px-6 pt-2 bg-gray-100 text-left text-sm leading-4 font-medium text-yellow-600 text-center">
                      B<span className="hidden sm:inline">ianco</span>
                    </th>
                    <th className="px-4 sm:px-6 pt-2 bg-gray-100 text-left text-sm leading-4 font-medium text-purple-600 text-center">
                      M<span className="hidden sm:inline">inipasto</span>
                    </th>
                  </>
                )}
                <th
                  rowSpan={2}
                  className="px-4 sm:px-6 pt-2 bg-gray-100 text-left text-sm leading-4 font-medium text-gray-600 uppercase align-middle"
                >
                  <button
                    type="submit"
                    className={classNames(
                      'hover:shadow-xl focus:shadow-outline focus:outline-none text-white leading-5 py-2 px-5 rounded transition-colors duration-150',
                      {
                        'bg-blue-600 hover:bg-blue-700': !sezioneSel.presenze,
                      },
                      {
                        'bg-green-500 hover:bg-green-600': sezioneSel.presenze,
                      }
                    )}
                    onClick={handleSalva}
                  >
                    Salva
                  </button>
                </th>
              </tr>
              <tr>
                <th className="px-4 sm:px-6 pb-2 bg-gray-100 text-left text-sm leading-4 font-black text-green-500 uppercase text-center align-middle">
                  {totali.P}
                </th>
                <th className="px-4 sm:px-6 pb-2 bg-gray-100 text-left text-sm leading-4 font-black text-red-500 uppercase text-center">
                  {totali.A}
                </th>
                {servizioId === 1 && (
                  <>
                    <th className="px-4 sm:px-6 pb-2 bg-gray-100 text-left text-sm leading-4 font-black text-yellow-600 uppercase text-center">
                      {totali.B}
                    </th>
                    <th className="px-4 sm:px-6 pb-2 bg-gray-100 text-left text-sm leading-4 font-black text-purple-500 uppercase text-center">
                      {totali.M}
                    </th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {alunni.map((alunno) => {
                return (
                  <tr key={alunno.id}>
                    <td className="px-4 sm:px-6 py-4 border-b border-gray-200">
                      <Link
                        to={`/app/anagrafica/alunno/${alunno.id}/servizio/${servizioId}`}
                        className="text-gray-900 hover:text-blue-500"
                      >
                        <b>{alunno.cognome}</b> {alunno.nome}
                      </Link>
                    </td>

                    <td className="px-4 sm:px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-center">
                      <input
                        type="radio"
                        name={`${alunno.id}`}
                        className="form-radio text-green-500"
                        checked={alunno.presenza === 'P'}
                        onChange={() => handleChangePresenza(alunno.id, 'P')}
                      />
                    </td>
                    <td className="px-4 sm:px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-center">
                      <input
                        type="radio"
                        name={`${alunno.id}`}
                        className="form-radio text-red-500"
                        checked={alunno.presenza === 'A'}
                        onChange={() => handleChangePresenza(alunno.id, 'A')}
                      />
                    </td>
                    {servizioId === 1 && (
                      <>
                        <td className="px-4 sm:px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-center">
                          <input
                            type="radio"
                            name={`${alunno.id}`}
                            className="form-radio text-yellow-500"
                            checked={alunno.presenza === 'B'}
                            onChange={() => handleChangePresenza(alunno.id, 'B')}
                          />
                        </td>
                        <td className="px-4 sm:px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-center">
                          <input
                            type="radio"
                            name={`${alunno.id}`}
                            className="form-radio text-purple-500"
                            checked={alunno.presenza === 'M'}
                            onChange={() => handleChangePresenza(alunno.id, 'M')}
                          />
                        </td>
                      </>
                    )}
                    <td className="px-4 sm:px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-center">
                      <button
                        className="text-blue-500 hover:text-blue-700"
                        onClick={() => {
                          openModal(alunno)
                          reset(alunno.fruizione)
                        }}
                      >
                        <FiEdit />
                      </button>
                    </td>
                  </tr>
                )
              })}
              <tr>
                <td colSpan={6} className="bg-blue-100 py-4 px-4">
                  <p className="leading-none mb-2 text-blue-700 font-semibold">Nuovo alunno:</p>
                  <CreatableSelect
                    isClearable
                    options={alunniAltreSezioni}
                    onInputChange={setCercaAlunno}
                    isLoading={fetchingAlunniAltreSezioni}
                    formatCreateLabel={(inputValue) => `Crea "${inputValue}"`}
                    noOptionsMessage={() => 'Nessun alunno'}
                    placeholder="Seleziona da altra classe o crea nuovo..."
                    //onCreateOption={(v: any) => console.log('V',v)}
                    onChange={handleChangeNuovoAlunno}
                    styles={reactSelectCustomStyles}
                    value={null}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
      <ReactModal
        isOpen={!!alunnoSel}
        onRequestClose={() => {
          setAlunnoSel(null)
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        style={modalCustomStyles}
      >
        <div className="max-w-lg">
          <div className="bg-blue-600 text-white font-semibold py-2 text-center">
            {alunnoSel?.cognome} {alunnoSel?.nome}
          </div>

          <div className="px-10 py-6">
            <form onSubmit={handleSubmit(handleModificaFruizione)}>
              {servizioId === 1 && (
                <div>
                  <div className="leading-5 font-medium text-gray-800 mb-3">
                    Giorni di fruizione
                  </div>
                  <div className="flex">
                    <FormField
                      fieldName="lunBidella"
                      label="L"
                      type={FormInputType.WeekDay}
                      register={register}
                      errors={errors}
                    />
                    <FormField
                      fieldName="marBidella"
                      label="M"
                      type={FormInputType.WeekDay}
                      register={register}
                      errors={errors}
                    />
                    <FormField
                      fieldName="merBidella"
                      label="M"
                      type={FormInputType.WeekDay}
                      register={register}
                      errors={errors}
                    />
                    <FormField
                      fieldName="gioBidella"
                      label="G"
                      type={FormInputType.WeekDay}
                      register={register}
                      errors={errors}
                    />
                    <FormField
                      fieldName="venBidella"
                      label="V"
                      type={FormInputType.WeekDay}
                      register={register}
                      errors={errors}
                    />
                  </div>
                  <hr className="my-6"></hr>
                </div>
              )}
              <div className="mb-5">
                <FormField
                  fieldName="dataRitiroBidella"
                  label="Data ritiro"
                  type={FormInputType.Date}
                  register={register}
                  errors={errors}
                />
              </div>
              <div className="mb-4">
                <FormField
                  fieldName="noteBidella"
                  label="Note"
                  type={FormInputType.Textarea}
                  rows={2}
                  register={register}
                  errors={errors}
                />
              </div>

              <div className="flex justify-between">
                <button
                  type="button"
                  className="block bg-gray-500 hover:bg-gray-600 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                  onClick={() => closeModal()}
                >
                  Annulla
                </button>

                <button
                  type="submit"
                  className="block bg-blue-600 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                >
                  Salva
                </button>
              </div>
            </form>
          </div>
        </div>
      </ReactModal>
    </div>
  )
}

//PresenzeGiornaliere.whyDidYouRender = true

export default PresenzeGiornaliere
