import { useQuery } from 'react-query'
import axios from 'axios'

interface GetAlunniArgs {
  cognome: string
  nome: string
  codiceFiscale: string
  annoScolastico?: string
}

const getAlunni = async (
  _: string,
  { cognome, nome, codiceFiscale, annoScolastico }: GetAlunniArgs
) => {
  const queryCognome = cognome ? `&cognome=${cognome}` : ''
  const queryNome = nome ? `&nome=${nome}` : ''
  const queryCodiceFiscale = codiceFiscale ? `&codiceFiscale=${codiceFiscale}` : ''

  const { data } = await axios.get(
    `/alunni/ricerca?annoScolastico=${annoScolastico}${queryCognome}${queryNome}${queryCodiceFiscale}`
  )
  return data.results
}

const useAlunni = ({ cognome, nome, codiceFiscale, annoScolastico }: GetAlunniArgs) => {
  return useQuery(
    (cognome.length >= 2 || codiceFiscale.length >= 3) && [
      'alunni',
      { cognome, nome, codiceFiscale, annoScolastico },
    ],
    getAlunni,
    {
      staleTime: 10 * 60 * 1000,
    }
  )
}

export default useAlunni
