import React, { useState } from 'react'
import { RouteComponentProps } from '@reach/router'
// import ReactModal from 'react-modal'
import InlineEdit, { InputType } from 'riec'
// import moment from 'moment'
// import { FiFileText } from 'react-icons/fi'

import { AgGridReact } from 'ag-grid-react'
// import { CellClassParams } from 'ag-grid-community'
// import classNames from 'classnames'

const SERVIZI = [
  { value: '1', label: 'Asili nido' },
  { value: '2', label: 'Ristorazione' },
]
const SCUOLE = [
  { value: '1', label: "L'albicocco Redona" },
  { value: '2', label: 'Arcobaleno Celadina' },
]

enum TipoPagamento {
  SDD = 'sdd',
  Bonifico = 'bonifico',
}

interface Sollecito {
  id: number
  data: string
  tipo: string
}

interface AvvisoPagamento {
  id: number
  codice: string
  alunno: {
    cognome: string
    nome: string
    classe: string
  }
  intestatario: {
    cognome: string
    nome: string
  }
  mese: number
  importo: number
  importoGenitore: number
  importoPagato: number
  dataPagamento: string
  tipoPagamento: TipoPagamento
  note: string[]
  solleciti: Sollecito[]
  numeroSolleciti: number
}

const AVVISI: AvvisoPagamento[] = [
  {
    id: 1,
    codice: 'AD7CSB',
    alunno: {
      cognome: 'Bacuzzi',
      nome: 'Giovanni',
      classe: '1 A',
    },
    intestatario: {
      cognome: 'Bacuzzi',
      nome: 'Giacomo',
    },
    mese: 3,
    importo: 250,
    importoGenitore: 250,
    importoPagato: 0,
    dataPagamento: '',
    tipoPagamento: TipoPagamento.Bonifico,
    note: ['Saldato € 150 in data 04/11/2023', 'Pagata insieme alla BGF232'],
    solleciti: [],
    numeroSolleciti: 0,
  },
  {
    id: 1,
    codice: 'VGKS34',
    alunno: {
      cognome: 'Bonetti',
      nome: 'Matteo',
      classe: '1 A',
    },
    intestatario: {
      cognome: 'Bonetti',
      nome: 'Fabio',
    },
    mese: 3,
    importo: 459,
    importoGenitore: 30,
    importoPagato: 459,
    dataPagamento: '2020-04-23',
    tipoPagamento: TipoPagamento.Bonifico,
    note: [],
    solleciti: [],
    numeroSolleciti: 0,
  },
]

// const modalCustomStyles = {
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//     boxShadow:
//       '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
//     padding: 0,
//     border: 'none',
//   },
//   overlay: {
//     backgroundColor: 'hsla(207, 40%, 60%, 0.7)',
//   },
// }

const PagamentiSolleciti: React.FC<RouteComponentProps> = () => {
  const [servizioSel, setServizioSel] = useState('1')
  const [scuolaSel, setScuolaSel] = useState('1')

  const columnDefs = [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      pinned: true,
      width: 30
    },
    {
      headerName: 'N° soll.',
      field: 'numeroSolleciti',
      sortable: true,
      filter: true,
      pinned: true,
      width: 120,
    },
    {
      headerName: 'Cognome',
      field: 'alunno.cognome',
      sortable: true,
      filter: true,
      pinned: true,
      width: 120,
    },
    {
      headerName: 'Nome',
      field: 'alunno.nome',
      sortable: true,
      filter: true,
      pinned: true,
      width: 120,
    },
    {
      headerName: 'Classe',
      field: 'alunno.classe',
      sortable: true,
      filter: true,
      width: 120,
    },
    {
      headerName: 'Mese',
      field: 'mese',
      sortable: true,
      filter: true,
      width: 120,
    },
    {
      headerName: 'Cod',
      field: 'codice',
      sortable: true,
      filter: true,
      width: 120,
    },
    {
      headerName: 'Importo',
      field: 'importo',
      sortable: true,
      filter: true,
      width: 120,
    },
    {
      headerName: 'Genitore',
      field: 'importoGenitore',
      sortable: true,
      filter: true,
      width: 120,
    },
    {
      headerName: 'Pagamento',
      field: 'tipoPagamento',
      sortable: true,
      filter: true,
      width: 120,
    },
    {
      headerName: 'Importo Pag.',
      field: 'importoPagato',
      sortable: true,
      filter: true,
      width: 120,
    },
    {
      headerName: 'Data Pagamento',
      field: 'dataPagamento',
      sortable: true,
      filter: true,
      width: 120,
    },
    {
      headerName: 'Note',
      field: 'note',
      sortable: true,
      filter: true,
      width: 120,
    },
    {
      headerName: 'Solleciti',
      field: 'solleciti',
      sortable: true,
      filter: true,
      width: 120,
    },
  ]

  return (
    <div className="bg-white py-6">
      <style>
        {`
        .ag-theme-alpine * {
          border-style: none;
        }
        .ag-react-container {
          height: 100%;
        }
      `}
      </style>
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-lg leading-tight mt-3 mb-4 text-gray-600 flex items-center">
          <InlineEdit
            type={InputType.Select}
            value={servizioSel}
            onChange={(v) => setServizioSel(v)}
            options={SERVIZI}
            editClass="form-select text-lg"
            viewClass="cursor-pointer text-blue-500"
          />
          <span className="mx-3 text-gray-400">&raquo;</span>
          <InlineEdit
            type={InputType.Select}
            value={scuolaSel}
            onChange={(v) => setScuolaSel(v)}
            options={SCUOLE}
            editClass="form-select text-lg"
            viewClass="cursor-pointer text-blue-500"
          />
        </h2>
        <h1 className="text-4xl leading-tight mb-8">Pagamenti e solleciti</h1>

        <div
          className="ag-theme-alpine"
          style={{
            height: '500px',
            width: '960px',
          }}
        >
          <AgGridReact
            columnDefs={columnDefs}
            rowSelection="multiple"
            rowMultiSelectWithClick
            rowData={AVVISI}
          ></AgGridReact>
        </div>
      </div>
    </div>
  )
}

export default PagamentiSolleciti
