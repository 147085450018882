import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import useUpdateFruizione from '../../../../apiHooks/mutations/useUpdateFruizione'
import ComuneBergamoMensa from './ComuneBergamoMensa'
import ComuneBergamoAsilo from './ComuneBergamoAsilo'
import { getLabelByValue } from '../../../../lib/tipoFrequenzaOptions'

interface FormServizioProps {
  fruizione: any
  intestatari: any[]
}

const getFruizioneValues = (fruizione: any) => {
  return {
    ...fruizione,
    intestatario: fruizione.intestatario
      ? {
          value: fruizione.intestatario.id,
          label: `${fruizione.intestatario.nome} ${fruizione.intestatario.cognome}`,
        }
      : null,
    tipoFrequenza: fruizione.tipoFrequenza
      ? {
          value: fruizione.tipoFrequenza,
          label: getLabelByValue(fruizione.tipoFrequenza),
        }
      : null,
  }
}

const FormServizio: React.FC<FormServizioProps> = ({
  fruizione,
  intestatari,
}) => {
  const { register, handleSubmit, errors, control, reset, watch } = useForm({
    defaultValues: getFruizioneValues(fruizione),
  })

  const [updateFruizione] = useUpdateFruizione()

  useEffect(() => {
    reset(getFruizioneValues(fruizione))
  }, [fruizione, reset])

  const onSubmit = (data: any) => {
    const fruizioneToSave = {
      ...data,
      intestatarioId: data.intestatario ? data.intestatario.value : null,
      tipoFrequenza: data.tipoFrequenza ? data.tipoFrequenza.value : null,
      dataInizio: data.dataInizio || null,
      dataRitiro: data.dataRitiro || null,
      dataRitiroBidella: data.dataRitiroBidella || null,
      dataFineAmbientamento: data.dataFineAmbientamento || null,
      // RENDERE GENERICO
      isee: data.isee ? parseFloat(data.isee) : null,
      dataInizioRitiroTemporaneo: data.dataInizioRitiroTemporaneo || null,
      dataFineRitiroTemporaneo: data.dataFineRitiroTemporaneo || null,
      asiloGratisQuotaGenitore: data.asiloGratisQuotaGenitore
        ? parseFloat(data.asiloGratisQuotaGenitore)
        : null,
      riduzioneMaggiorazioneFissaImporto: data.riduzioneMaggiorazioneFissaImporto
        ? parseFloat(data.riduzioneMaggiorazioneFissaImporto)
        : null,
      riduzioneMaggiorazioneTemporaneaImporto: data.riduzioneMaggiorazioneTemporaneaImporto
        ? parseFloat(data.riduzioneMaggiorazioneTemporaneaImporto)
        : null,
      riportoRiduzione: data.riportoRiduzione
        ? parseFloat(data.riportoRiduzione)
        : null,
    }
    updateFruizione({ fruizioneId: fruizione.id, fruizione: fruizioneToSave })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      {fruizione.servizioId === 1 && (
        <ComuneBergamoMensa
          register={register}
          errors={errors}
          control={control}
          watch={watch}
          intestatari={intestatari}
        />
      )}
      {fruizione.servizioId === 2 && (
        <ComuneBergamoAsilo
          register={register}
          errors={errors}
          control={control}
          watch={watch}
          intestatari={intestatari}
        />
      )}
    </form>
  )
}

export default FormServizio
