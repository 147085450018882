import { useMutation, queryCache } from 'react-query'
import axios from 'axios'

interface GenerazioneAvvisi {
  mese: number
  anno: number
  scuolaId: number
  dataEmissione: string
  dataScadenza: string
  periodo: string
  nota?: string
}

const generaAvvisiPagamento = async (servizioId: number, avviso: GenerazioneAvvisi) => {
  const { data } = await axios.post(`/servizi/${servizioId}/genera-avvisi-pagamento/`, avviso)
  return data
}

const useGeneraAvvisiPagamento = () => {
  return useMutation(
    ({ servizioId, avviso }: { servizioId: number; avviso: GenerazioneAvvisi }) => {
      return generaAvvisiPagamento(servizioId, avviso)
    },
    {
      onSuccess: (data: any, variables: any) => {
        // console.log('OK! - ', data)
        queryCache.setQueryData(
          [
            'presenzeMese',
            {
              servizioId: variables.servizioId,
              scuolaId: variables.avviso.scuolaId,
              mese: variables.avviso.mese,
              anno: variables.avviso.anno,
            },
          ],
          data
        )
      },
      onError: () => {},
    }
  )
}

export default useGeneraAvvisiPagamento
