import { useMutation, queryCache } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

interface IUpdateFruizione {
  fruizioneId?: number
  fruizione?: any
}

const updateFruizione = async ({
  fruizioneId,
  fruizione,
}: IUpdateFruizione) => {
  const { data } = await axios.patch(`/fruizioni/${fruizioneId}/`, fruizione)
  return data
}

const useUpdateFruizione = () => {
  return useMutation(
    ({ fruizioneId, fruizione }: { fruizioneId: number, fruizione: any }) => {
      return updateFruizione({ fruizioneId, fruizione })
    },
    {
      onSuccess: (res: any) => {
        queryCache.refetchQueries('alunnoByIdByAnno')
        queryCache.refetchQueries('presenzeAlunniData')
        queryCache.refetchQueries('countFruizioniDaControllare', { force: true })
        cogoToast.success('Servizio aggiornato')
      },
      onError: () => {},
    }
  )
}

export default useUpdateFruizione
