import { useQuery } from 'react-query'
import axios from 'axios'

interface IStatoAvvisiPagamento {
  servizioId: number
  scuolaId: string
  mese: number
  anno: number
}

const getStatoAvvisiPagamento = async (
  _: string,
  { servizioId, scuolaId, mese, anno }: IStatoAvvisiPagamento
) => {
  const { data } = await axios.get(
    `/servizi/${servizioId}/stato-avvisi-pagamento/?scuolaId=${scuolaId}&mese=${mese}&anno=${anno}`
  )
  return data
}

const useStatoAvvisiPagamento = ({ servizioId, scuolaId, mese, anno }: IStatoAvvisiPagamento) => {
  return useQuery(
    !!servizioId &&
      !!scuolaId &&
      !!mese &&
      !!anno && ['presenzeMese', { servizioId, scuolaId, mese, anno }],
    getStatoAvvisiPagamento, {
      refetchInterval: 30 * 1000
    }
  )
}

export default useStatoAvvisiPagamento
