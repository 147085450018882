import React, { useState, useContext } from 'react'
import { Link, navigate } from '@reach/router'
import { queryCache } from 'react-query'
import InlineEdit, { InputType } from 'riec'
import classNames from 'classnames'
import { FiUser, FiPower, FiCoffee } from 'react-icons/fi'

import AnnoScolasticoContext from '../context/AnnoScolasticoContext'
import ServizioContext from '../context/ServizioContext'

import useProfile from '../apiHooks/queries/useProfile'
import useServizi from '../apiHooks/queries/useServizi'

import logo from '../images/logo.svg'
import './Header.css'

const isPartiallyActive = ({
  isPartiallyCurrent,
}: {
  isPartiallyCurrent: boolean
}) => {
  return isPartiallyCurrent
    ? { className: 'pt-1 pb-3 mr-8 border-b-2 border-blue-500' }
    : {}
}

const Header: React.FC = () => {
  const { annoScolastico, handleChangeAnnoScolastico } = useContext(
    AnnoScolasticoContext
  )
  const { servizioId, handleChangeServizioId } = useContext(ServizioContext)

  const { data: user } = useProfile(' ')
  const { data: servizi } = useServizi()

  const [userMenuOpen, setUserMenuOpen] = useState(false)

  const handleLogout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('username')
    localStorage.removeItem('nome')
    localStorage.removeItem('cognome')
    queryCache.clear()
    navigate('/')
  }

  const handlePausa = () => {
    localStorage.removeItem('token')
    queryCache.clear()
    navigate('/pausa')
  }

  if (!user || !servizi) {
    return null
  }

  return (
    <header className="bg-white border-b border-gray-300">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <nav className="bg-white">
          <div className="flex items-center justify-between h-16">
            <div className="left flex items-center">
              <img src={logo} alt="Rette Web" className="w-12 mr-4" />
              <div className="text-sm text-gray-700">
                <div className="flex items-center">
                  <div>Comune di Bergamo</div>
                  <div className="mx-2 text-gray-400">•</div>
                  <div>
                    <InlineEdit
                      type={InputType.Select}
                      value={servizioId + ''}
                      onChange={(v) => handleChangeServizioId(+v)}
                      options={user?.servizi.map((s: any) => ({
                        value: s + '',
                        label: servizi.find(
                          (servizio: any) => servizio.id === s
                        ).nome,
                      }))}
                      viewClass="cursor-pointer font-semibold hover:text-blue-600"
                      editClass="form-select text-sm"
                      loadingClass="text-red-600"
                    />
                  </div>
                  <div className="mx-2 text-gray-400">•</div>
                  <div>
                    <InlineEdit
                      type={InputType.Select}
                      value={annoScolastico}
                      onChange={handleChangeAnnoScolastico}
                      options={[
                        { value: '2018-2019', label: '2018-2019' },
                        { value: '2019-2020', label: '2019-2020' },
                        { value: '2020-2021', label: '2020-2021' },
                      ]}
                      viewClass="cursor-pointer font-semibold hover:text-blue-600"
                      editClass="form-select text-sm"
                      loadingClass="text-red-600"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="right flex items-center relative">
              <div
                className="w-8 h-8 border rounded-full flex justify-center items-center text-blue-500 bg-blue-100 border-blue-200 cursor-pointer"
                onClick={() => setUserMenuOpen((userMenuOpen) => !userMenuOpen)}
              >
                <FiUser />
              </div>
              <div
                className="text-sm text-gray-600 ml-2 leading-none cursor-pointer"
                onClick={() => setUserMenuOpen((userMenuOpen) => !userMenuOpen)}
              >
                {user?.nome} {user?.cognome}
              </div>
              <div
                className={classNames(
                  'absolute top-0 right-0 mt-10 rounded-md shadow-lg',
                  { hidden: !userMenuOpen }
                )}
                style={{ width: 150 }}
              >
                <div className="py-1 rounded-md bg-white shadow-xs">
                  <button
                    className="w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center user-menu-item"
                    onClick={handlePausa}
                  >
                    <FiCoffee className="text-gray-500 mr-2 pausa" />
                    Pausa Caffè
                  </button>
                  <button
                    className="w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 flex items-center user-menu-item"
                    onClick={handleLogout}
                  >
                    <FiPower className="text-gray-500 mr-2 esci" />
                    Esci
                  </button>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <nav className="bg-white">
          <div className="flex items-center justify-start text-sm">
            <Link
              to="anagrafica"
              className="pt-1 pb-3 mr-8 text-gray-600"
              getProps={isPartiallyActive}
            >
              Anagrafica
            </Link>
            <Link
              to="presenze"
              className="pt-1 pb-3 mr-8 text-gray-600"
              getProps={isPartiallyActive}
            >
              Presenze
            </Link>
            <Link
              to="avvisi-pagamento"
              className="pt-1 pb-3 mr-8 text-gray-600"
              getProps={isPartiallyActive}
            >
              Avvisi pagamento
            </Link>
            <Link
              to="parametri-servizio"
              className="pt-1 pb-3 text-gray-600"
              getProps={isPartiallyActive}
            >
              Parametri servizio
            </Link>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Header
