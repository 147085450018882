import { useQuery } from 'react-query'
import axios from 'axios'

const getCittadinanze = async (_: string, keyword: string, limit: number) => {
  const { data } = await axios.get(
    `/cittadinanze?search=${keyword}&limit=${limit}`
  )
  return data.results
}

const useCittadinanze = (keyword: string) => {
  return useQuery(['cittadinanze', keyword, 10], getCittadinanze, {
    // 30 minuti
    staleTime: 30 * 60 * 1000,
  })
}

export default useCittadinanze
