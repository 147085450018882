import { useQuery } from 'react-query'
import axios from 'axios'

interface IGiorniNonErogazioneByServizioId {
  servizioId: number
  from?: string
  to?: string
}

const getGiorniNonErogazioneByServizioId = async (
  _: string,
  { servizioId, from, to }: IGiorniNonErogazioneByServizioId
) => {
  const { data } = await axios.get(
    `/servizi/${servizioId}/giorni-non-erogazione/?from=${from}&to=${to}`
  )
  return data.results
}

const useGiorniNonErogazioneByServizioId = ({
  servizioId,
  from,
  to,
}: IGiorniNonErogazioneByServizioId) => {
  return useQuery(
    !!servizioId && [
      'giorniNonErogazioneByServizioId',
      { servizioId, from, to },
    ],
    getGiorniNonErogazioneByServizioId,
    {
      // 30 minuti
      staleTime: 30 * 60 * 1000,
    }
  )
}

export default useGiorniNonErogazioneByServizioId
