import React, { useState, useEffect, useContext } from 'react'
import { RouteComponentProps } from '@reach/router'
import DayPicker from 'react-day-picker'
import ReactModal from 'react-modal'
import moment from 'moment'
import { FiEye, FiFileText, FiSmile, FiAlertCircle } from 'react-icons/fi'

import { API_URL } from '../../../config'
import AnnoScolasticoContext from '../../../context/AnnoScolasticoContext'
import usePresenzeByFruizioneId from '../../../apiHooks/queries/usePresenzeByFruizioneId'
import useGiorniNonErogazioneByServizioId from '../../../apiHooks/queries/useGiorniNonErogazioneByServizioId'
import useBollettaFruizioneMese from '../../../apiHooks/queries/useBollettaFruizioneMese'
// import useBollettaPdfFruizioneMese from '../../../apiHooks/queries/useBollettaPdfFruizioneMese'
import useCreateFruizione from '../../../apiHooks/mutations/useCreateFruizione'
import FormServizio from './formServizi/FormServizio'
import {
  isNonErogazione,
  isErrore,
  isWarning,
} from '../../../lib/validazionePresenze'
import 'react-day-picker/lib/style.css'

interface PropsServizio extends RouteComponentProps {
  id?: string
  alunnoId: number
  fruizioni?: any[]
  intestatari: any[]
}

const modifiersStyles = {
  p: {
    color: '#48bb78',
  },
  a: {
    color: '#e53e3e',
  },
  b: {
    color: '#48bb78',
  },
  m: {
    color: '#48bb78',
  },
  nonErogazione: {
    backgroundColor: '#f0f0f0',
  },
  // errore: {
  //   //border: '2px solid #feb2b2',
  // },
  // warning: {
  //   border: '2px solid #fc0',
  //   // backgroundColor: '#fc0',
  // },
  outside: {
    border: 'none!important',
  },
  disabled: {
    backgroundColor: 'transparent!important',
  },
}

const modalCustomStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow:
      '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    padding: 0,
    border: 'none',
  },
  overlay: {
    backgroundColor: 'hsla(207, 40%, 60%, 0.7)',
  },
}

const MONTHS = [
  'Gennaio',
  'Febbraio',
  'Marzo',
  'Aprile',
  'Maggio',
  'Giugno',
  'Luglio',
  'Agosto',
  'Settembre',
  'Ottobre',
  'Novembre',
  'Dicembre',
]
const WEEKDAYS_LONG = [
  'Domenica',
  'Lunedì',
  'Martedì',
  'Mercoledì',
  'Giovedì',
  'Venerdì',
  'Sabato',
]
const WEEKDAYS_SHORT = ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa']

const getRangeDate = (date: Date) => {
  const momentDate = moment(date)
  return {
    from: momentDate.startOf('month').format('YYYY-MM-DD'),
    to: momentDate.endOf('month').format('YYYY-MM-DD'),
  }
}

const getDatePresenzeByTipo = (presenze: any[], tipo: string) => {
  return presenze
    .filter((p: any) => p.tipo === tipo)
    .map((p: any) => moment(p.data, 'YYYY-MM-DD', true).toDate())
}

const getDate = (giorni: any[]) => {
  return giorni.map((g) => moment(g.data, 'YYYY-MM-DD', true).toDate())
}

const Servizio: React.FC<PropsServizio> = ({
  id,
  fruizioni,
  intestatari,
  alunnoId,
}) => {
  const { annoScolastico } = useContext(AnnoScolasticoContext)
  const anni: string[] = annoScolastico.split('-')
  const dataMin = moment(`${anni[0]}-09-01`, 'YYYY-MM-DD', true)
  const dataMax = moment(`${anni[1]}-08-31`, 'YYYY-MM-DD', true)
  const todayMoment = moment()
  const initialMonth = todayMoment.isBefore(dataMin)
    ? dataMin.toDate()
    : todayMoment.isAfter(dataMax)
    ? dataMax.toDate()
    : todayMoment.toDate()

  const fruizione =
    fruizioni && fruizioni.find((f: any) => f.servizioId === +id!)

  const today = new Date()
  const initialRange = getRangeDate(today)

  const [currentDate, setCurrentDate] = useState(today)
  const [from, setFrom] = useState(initialRange.from)
  const [to, setTo] = useState(initialRange.to)
  const [fruizioneIdCalcolo, setFruizioneIdCalcolo] = useState<number>(0)
  // const [fruizioneIdPdf, setFruizioneIdPdf] = useState<number>(0)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  // const [bolletta, setBolletta] = useState<Bolletta | null>(null)

  const { data: presenze = [] } = usePresenzeByFruizioneId({
    fruizioneId: fruizione ? fruizione.id : null,
    from,
    to,
  })

  const { data: giorniNonErogazione = [] } = useGiorniNonErogazioneByServizioId(
    {
      servizioId: +id!,
      from,
      to,
    }
  )

  const { data: bollettaMese } = useBollettaFruizioneMese({
    fruizioneId: fruizioneIdCalcolo,
    anno: moment(currentDate).format('YYYY'),
    mese: moment(currentDate).format('M'),
  })

  // const { data: bollettaPdf, isFetching: isFetchingBollettaPdf } = useBollettaPdfFruizioneMese({
  //   fruizioneId: fruizioneIdPdf,
  //   anno: moment(currentDate).format('YYYY'),
  //   mese: moment(currentDate).format('M'),
  // })

  const [createFruizione] = useCreateFruizione({
    servizioId: +id!,
    alunnoId,
    annoScolastico,
  })

  // useEffect(() => {
  //   const bolletta: Bolletta = bollettaMese
  //   !!fruizioneIdCalcolo && setBolletta(bolletta)
  // }, [fruizioneIdCalcolo, isFetchingBollettaMese])

  const handleAnteprimaBolletta = (event: any) => {
    document.getElementById('root')!.style.filter = 'blur(5px)'
    // const bolletta: Bolletta = bollettaMese

    setFruizioneIdCalcolo(fruizione ? fruizione.id : 0)
    //setBolletta(bolletta)
    setModalOpen(true)
  }

  // const handleBollettaPdf = (event: any) => {
  //   setFruizioneIdPdf(fruizione ? fruizione.id : 0,)
  // }

  useEffect(() => {
    const range = getRangeDate(currentDate)
    setFrom(range.from)
    setTo(range.to)
  }, [currentDate])

  const handleChangeMonth = (date: Date) => {
    setCurrentDate(date)
  }

  const markError = (day: Date) => {
    const data = moment(day).format('YYYY-MM-DD')
    const presenzaGiornaliera = presenze.find((p: any) => p.data === data)
    const tipoPresenza = presenzaGiornaliera && presenzaGiornaliera.tipo
    return isErrore(data, tipoPresenza, giorniNonErogazione, fruizione)
  }

  const markWarning = (day: Date) => {
    const data = moment(day).format('YYYY-MM-DD')
    const presenzaGiornaliera = presenze.find((p: any) => p.data === data)
    const tipoPresenza = presenzaGiornaliera && presenzaGiornaliera.tipo
    return isWarning(data, tipoPresenza, fruizione)
  }

  const modifiers = {
    p: getDatePresenzeByTipo(presenze, 'P'),
    a: getDatePresenzeByTipo(presenze, 'A'),
    b: getDatePresenzeByTipo(presenze, 'B'),
    m: getDatePresenzeByTipo(presenze, 'M'),
    nonErogazione: getDate(giorniNonErogazione),
    errore: markError,
    warning: markWarning,
    disabled: [
      { daysOfWeek: [0, 6] },
      (d: Date) =>
        isNonErogazione(giorniNonErogazione, moment(d).format('YYYY-MM-DD')),
    ],
  }

  const renderDay = (day: Date) => {
    const data = moment(day).format('YYYY-MM-DD')
    const nonErogazione = isNonErogazione(giorniNonErogazione, data)
    let descrizioneNonErogazione = ''
    if (nonErogazione) {
      descrizioneNonErogazione = nonErogazione.descrizione
    }
    return (
      <>
        <span title={descrizioneNonErogazione} className="leading-none">
          {moment(day).format('D')}
        </span>
        {/* <div
          className="triangle absolute top-0 right-0"
          style={{
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderWidth: '0 8px 8px 0',
            borderColor: 'transparent'
          }}
        ></div> */}
        <div
          className="point absolute w-1 h-1 rounded-full"
          style={{
            top: 4,
            left: 15,
          }}
        ></div>
      </>
    )
  }

  return (
    <div className="flex-1 lg:pl-32 lg:flex lg:-mx-10 pt-6 pb-20">
      <div className="md:w-1/2 md:px-10">
        {/* <h2 className="font-semibold mb-4">Parametri servizio</h2> */}
        {fruizione ? (
          <FormServizio fruizione={fruizione} intestatari={intestatari} />
        ) : (
          <div>
            <p className="mb-6">
              Questo servizio non è attivo per questo alunno.
            </p>
            <button
              className="bg-blue-600 hover:bg-blue-500 py-3 w-full text-xl text-white rounded"
              onClick={() => createFruizione()}
            >
              Attiva il servizio
            </button>
          </div>
        )}
      </div>
      <div className="md:w-1/2 md:px-10 flex justify-end">
        {fruizione && (
          <div>
            <h2 className="font-semibold mb-4">Presenze mensili</h2>
            <div style={{ lineHeight: 1.15 }}>
              <style>{`
                .DayPicker-Day {
                  position: relative;
                }
                .DayPicker-Day--errore .point {
                 background-color: #ff5722;
                }
                .DayPicker-Day--warning .point {
                  background-color: #ffc905;
                }
            `}</style>
              <DayPicker
                className="bg-white rounded py-2 px-2 mb-8 shadow-lg border-gray-200 "
                locale="it"
                months={MONTHS}
                initialMonth={initialMonth}
                fromMonth={dataMin.toDate()}
                toMonth={dataMax.toDate()}
                weekdaysLong={WEEKDAYS_LONG}
                weekdaysShort={WEEKDAYS_SHORT}
                firstDayOfWeek={1}
                //disabledDays={{ daysOfWeek: [0, 6] }}
                modifiers={modifiers}
                modifiersStyles={modifiersStyles}
                onMonthChange={handleChangeMonth}
                renderDay={renderDay}
              />
            </div>

            <div className="flex justify-between">
              <button
                className="block border border-blue-400 hover:bg-white hover:border-blue-600 hover:shadow-xl focus:shadow-outline focus:outline-none text-blue-600 hover:text-blue-700 text-sm leading-5 py-2 px-5 rounded transition-colors duration-150 mb-12"
                onClick={handleAnteprimaBolletta}
              >
                Anteprima Bolletta
              </button>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${API_URL}/fruizioni/${fruizione.id}/pdf/?anno=${moment(
                  currentDate
                ).format('YYYY')}&mese=${moment(currentDate).format(
                  'M'
                )}&t=${encodeURIComponent(
                  localStorage.getItem('token') || ''
                )}`}
                className="block border border-blue-400 hover:bg-white hover:border-blue-600 hover:shadow-xl focus:shadow-outline focus:outline-none text-blue-600 hover:text-blue-700 text-sm leading-5 py-2 px-5 rounded transition-colors duration-150 mb-12"
              >
                Bolletta PDF
              </a>
            </div>

            <h2 className="font-semibold mb-4">Avvisi di pagamento</h2>
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Mese
                  </th>
                  <th className="px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider text-right">
                    Importo
                  </th>
                  <th className="px-6 py-3 border-b border-gray-200 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                    Pag
                  </th>
                  <th className="px-6 py-3 border-b border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
                </tr>
              </thead>
              <tbody className="text-sm">
                <tr>
                  <td className="px-6 py-2 whitespace-no-wrap border-b border-gray-200">
                    SET
                  </td>
                  <td className="px-6 py-2 whitespace-no-wrap border-b border-gray-200 text-right">
                    € 180,00
                  </td>
                  <td className="px-6 py-2 whitespace-no-wrap border-b border-gray-200">
                    <FiSmile className="text-green-400 text-xl mx-auto" />
                  </td>
                  <td className="px-6 py-2 whitespace-no-wrap border-b border-gray-200">
                    <div className="flex">
                      <a href="#" className="text-blue-500 mr-3">
                        <FiEye />
                      </a>{' '}
                      <a href="#" className="text-blue-500">
                        <FiFileText />
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="px-6 py-2 whitespace-no-wrap border-b border-gray-200">
                    OTT
                  </td>
                  <td className="px-6 py-2 whitespace-no-wrap border-b border-gray-200 text-right">
                    € 84,23
                  </td>
                  <td className="px-6 py-2 whitespace-no-wrap border-b border-gray-200">
                    <FiAlertCircle className="text-red-500 text-xl mx-auto" />
                  </td>
                  <td className="px-6 py-2 whitespace-no-wrap border-b border-gray-200">
                    <div className="flex">
                      <button className="text-blue-500 mr-3">
                        <FiEye />
                      </button>{' '}
                      <button className="text-blue-500">
                        <FiFileText />
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>

      <ReactModal
        isOpen={modalOpen}
        onRequestClose={() => {
          setFruizioneIdCalcolo(0)
          //setBolletta(null)
          setModalOpen(false)
          document.getElementById('root')!.style.filter = 'blur(0)'
        }}
        style={modalCustomStyles}
      >
        {!!bollettaMese && bollettaMese.righe && (
          <div className="max-w-lg">
            <div className="bg-blue-600 text-white font-semibold px-6 py-2 text-center">
              Bolletta di {moment(bollettaMese?.mese, 'M', true).format('MMMM')}{' '}
              {bollettaMese?.anno}
            </div>

            <div className="px-8 py-6">
              <table className="mx-auto">
                <tbody>
                  {bollettaMese?.righe.map((riga) => (
                    <tr key={riga.descrizione}>
                      <td className="pb-1 px-4">{riga.descrizione}</td>
                      <td className="pb-1 px-4 text-right">{riga.importo}</td>
                    </tr>
                  ))}
                  <tr className="border-t border-blue-300">
                    <td className="pt-2 px-4 uppercase font-bold">Totale</td>
                    <td className="pt-2 px-4 text-right font-bold">
                      {bollettaMese?.totale}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </ReactModal>
    </div>
  )
}

export default Servizio
