import React, { useState } from 'react'
import { RouteComponentProps, navigate } from '@reach/router'
import { Helmet } from 'react-helmet'

import useLogin from '../../apiHooks/mutations/useLogin'
import imgLogo from '../../images/logo_v.svg'

const Login: React.FC<RouteComponentProps> = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const [login] = useLogin()

  const handleLogin = (event: React.FormEvent) => {
    event.preventDefault()
    login({ username, password }).then((res) => {
      if (res && res.token) {
        localStorage.setItem('token', res.token)
        localStorage.setItem('username', res.user.username)
        localStorage.setItem('nome', res.user.operatore.nome)
        localStorage.setItem('cognome', res.user.operatore.cognome)
        navigate('/app')
      }
    })
  }
  // https://images.unsplash.com/photo-1488998427799-e3362cec87c3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80
  return (
    <div className="min-h-screen">
      <Helmet>
        <style>{`body { background-image: url(https://images.unsplash.com/photo-1464618663641-bbdd760ae84a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2850&q=80), linear-gradient(rgba(255,255,255,1),rgba(255,255,255,0.2)); background-blend-mode: overlay; background-size: cover; background-position: 50% 20% }`}</style>
      </Helmet>
      <header className="py-12 flex justify-center">
        <img
          src={imgLogo}
          alt="logo"
          className="w-32"
        />
      </header>

      <div className="shadow-lg py-8 px-10 max-w-sm rounded mx-auto bg-white">
        <h2 className="text-center text-gray-600 mb-6">Accedi al sistema</h2>
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label
              htmlFor="username"
              className="block text-sm leading-5 font-medium text-gray-700"
            >
              Nome utente
            </label>
            <input
              type="text"
              className="form-input w-full mt-1"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block text-sm leading-5 font-medium text-gray-700"
            >
              Password
            </label>
            <input
              type="password"
              className="form-input w-full mt-1"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="block w-full bg-blue-600 hover:bg-blue-500 focus:shadow-outline focus:outline-none text-white text-sm font-semibold leading-5 py-3 px-5 rounded transition-colors duration-150"
          >
            Accedi
          </button>
        </form>
      </div>
    </div>
  )
}

export default Login
