interface AlunnoDaControllare {
  id: number
  alunnoId: number
  cognome: string
  nome: string
  intestatarioId: number | null
  dataInizio: string | null
  dataFineAmbientamento: string | null
  tipoFrequenza: string | null
  isAsiloGratis: boolean
  asiloGratisQuotaGenitore: number | null
  pastiSettimanaliPrevisti: boolean | null
  pagamentoSdd: boolean
  codiceSdd: number | null
  modificatoBidella: boolean
  sezioneIdBidella: number | null
}

export const isAnomalia = (nome: string, value: any, secondValue?: any) => {
  if (nome === 'asiloGratis' || nome === 'SDD') {
    return !!value && !secondValue
  }

  if (
    nome === 'dataInizio' ||
    nome === 'dataFineAmbientamento' ||
    nome === 'intestatarioId' ||
    nome === 'tipoFrequenza' ||
    nome === 'pastiSettimanaliPrevisti'
  ) {
    return !value
  }

  return !!value
}

export const anomaliaAsiloGratis = (
  isAsiloGratis: boolean,
  asiloGratisQuotaGenitore: number | null
) => {
  return !!isAsiloGratis && !asiloGratisQuotaGenitore
}

export const anomaliaSDD = (pagamentoSdd: boolean, codiceSdd: number | null) => {
  return !!pagamentoSdd && !codiceSdd
}

export const hasAnomalieFruizioni = (alunno: AlunnoDaControllare, servizioId: number) => {
  const commonErrors =
    isAnomalia('intestatarioId', alunno.intestatarioId) ||
    isAnomalia('dataInizio', alunno.dataInizio) ||
    isAnomalia('SDD', alunno.pagamentoSdd, alunno.codiceSdd) ||
    isAnomalia('modificatoBidella', alunno.modificatoBidella) ||
    isAnomalia('sezioneIdBidella', alunno.sezioneIdBidella)

  const asiloErrors =
    isAnomalia('dataFineAmbientamento', alunno.dataFineAmbientamento) ||
    isAnomalia('tipoFrequenza', alunno.tipoFrequenza) ||
    isAnomalia('asiloGratis', alunno.isAsiloGratis, alunno.asiloGratisQuotaGenitore)

  const mensaErrors = isAnomalia('pastiSettimanaliPrevisti', alunno.pastiSettimanaliPrevisti)

  
  if (servizioId === 1) {
    return commonErrors || mensaErrors
  } else if (servizioId === 2) {
    return commonErrors || asiloErrors
  }
  
  return false
}
