import { useQuery } from 'react-query'
import axios from 'axios'

const getServizi = async () => {
  const { data } = await axios.get(`/servizi/`)
  return data.results
}

const useServizi = () => {
  return useQuery('servizi', getServizi, {
    // 30 minuti
    staleTime: 30 * 60 * 1000
  })
}

export default useServizi
