import { useQuery } from 'react-query'
import axios from 'axios'

const getAlunniBySezioneId = async (_: string, sezioneId: number) => {
  const { data } = await axios.get(`/sezioni/${sezioneId}/alunni`)
  return data.results
}

const useAlunniBySezioneId = (sezioneId: number) => {
  return useQuery(!!sezioneId && ['alunniBySezioneId', sezioneId], getAlunniBySezioneId)
}

export default useAlunniBySezioneId
