import React, { useContext } from 'react'
import { RouteComponentProps, Link } from '@reach/router'
import classNames from 'classnames'

import AnnoScolasticoContext from '../../../context/AnnoScolasticoContext'
import ServizioContext from '../../../context/ServizioContext'
import useCountAlunniDaControllare from '../../../apiHooks/queries/useCountAlunniDaControllare'
import useCountFruizioniDaControllare from '../../../apiHooks/queries/useCountFruizioniDaControllare'

const isActive = ({ isCurrent }: { isCurrent: boolean }) => {
  return isCurrent
    ? {
        className:
          'mr-4 py-1 px-3 bg-blue-200 border-blue-200 text-blue-800 text-sm font-medium rounded',
      }
    : {}
}

const Ricerche: React.FC<RouteComponentProps> = ({ children }) => {
  const { annoScolastico } = useContext(AnnoScolasticoContext)
  const { servizioId } = useContext(ServizioContext)

  const { data: alunniDaControllare } = useCountAlunniDaControllare({
    annoScolastico,
  })
  const { data: fruizioniDaControllare } = useCountFruizioniDaControllare({
    annoScolastico,
    servizioId,
  })

  return (
    <div>
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-6 flex">
          <Link
            to="./"
            className="mr-4 py-1 px-3 border border-gray-100 hover:border-blue-200 hover:bg-white text-gray-700 text-sm rounded transition-colors duration-150"
            getProps={isActive}
          >
            Elenco
          </Link>
          <Link
            to="ricerca"
            className="mr-4 py-1 px-3 border border-gray-100 hover:border-blue-200 hover:bg-white text-gray-700 text-sm rounded transition-colors duration-150"
            getProps={isActive}
          >
            Ricerca
          </Link>
          <Link
            to="anomalie-alunni"
            className="mr-4 py-1 px-3 border border-gray-100 hover:border-blue-200 hover:bg-white text-gray-700 text-sm rounded transition-colors duration-150"
            getProps={isActive}
          >
            <div className="flex items-center">
              Alunni da controllare{' '}
              <span className={classNames('text-white text-xs font-semibold w-4 h-4 flex justify-center items-center rounded-full ml-1',{'bg-red-600': alunniDaControllare}, {'bg-green-500': !alunniDaControllare})}>
                {alunniDaControllare}
              </span>
            </div>
          </Link>
          <Link
            to="anomalie-fruizioni"
            className="mr-4 py-1 px-3 border border-gray-100 hover:border-blue-200 hover:bg-white text-gray-700 text-sm rounded transition-colors duration-150"
            getProps={isActive}
          >
            <div className="flex items-center">
              Fruizioni da controllare{' '}
              <span className={classNames('text-white text-xs font-semibold w-4 h-4 flex justify-center items-center rounded-full ml-1',{'bg-red-600': fruizioniDaControllare}, {'bg-green-500': !fruizioniDaControllare})}>
                {fruizioniDaControllare}
              </span>
            </div>
          </Link>
        </div>
      </div>
      {children}
    </div>
  )
}

export default Ricerche
