import { useQuery } from 'react-query'
import axios from 'axios'

interface IPresenzeSezioni {
  scuolaId?: number
  servizioId: number
  dataPresenze: string
}

const getPresenzeSezioniData = async (_: string, { scuolaId, servizioId, dataPresenze }: IPresenzeSezioni) => {
  const { data } = await axios.get(`/scuole/${scuolaId}/presenze-sezioni/${servizioId}/?data=${dataPresenze}`)
  return data.results
}

const usePresenzeSezioniData = ({ scuolaId, servizioId, dataPresenze }: IPresenzeSezioni) => {
  return useQuery(!!scuolaId && !!servizioId && !!dataPresenze && ['presenzeSezioniData', { scuolaId, servizioId, dataPresenze}], getPresenzeSezioniData)
}

export default usePresenzeSezioniData
