import { useQuery } from 'react-query'
import axios from 'axios'

interface IPresenzeAlunni {
  sezioneId?: number
  servizioId: number
  dataPresenze: string
  cognome: string
}

const getAlunniAltreSezioni = async (_: string, { sezioneId, servizioId, dataPresenze, cognome }: IPresenzeAlunni) => {
  const { data } = await axios.get(`/sezioni/${sezioneId}/presenze-alunni/${servizioId}/?data=${dataPresenze}&mostra_ritirati=true&&escludi_sezione=true&&cognome=${cognome}`)
  return data.results.map((alunno: any) => ({ value: alunno.id, label: `${alunno.cognome} ${alunno.nome}`}))
}

const useAlunniAltreSezioni = ({ sezioneId, servizioId, dataPresenze, cognome }: IPresenzeAlunni) => {
  return useQuery(!!sezioneId && !!servizioId && !!dataPresenze && !!cognome && ['alunniAltreSezioni', { sezioneId, servizioId, dataPresenze, cognome}], getAlunniAltreSezioni)
}

export default useAlunniAltreSezioni
