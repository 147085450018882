import { useQuery } from 'react-query'
import axios from 'axios'

interface IPresenzeByFruizioneId {
  fruizioneId: number
  from?: string
  to?: string
}

const getPresenzeByFruizioneId = async (
  _: string,
  { fruizioneId, from, to }: IPresenzeByFruizioneId
) => {
  const { data } = await axios.get(
    `/fruizioni/${fruizioneId}/presenze/?from=${from}&to=${to}`
  )
  return data.results
}

const usePresenzeByFruizioneId = ({
  fruizioneId,
  from,
  to,
}: IPresenzeByFruizioneId) => {
  return useQuery(
    !!fruizioneId && [
      'presenzeByFruizioneId',
      { fruizioneId, from, to },
    ],
    getPresenzeByFruizioneId
  )
}

export default usePresenzeByFruizioneId
