import React, { useState, useEffect } from 'react'
import { RouteComponentProps, navigate } from '@reach/router'
import { useForm } from 'react-hook-form'

import { codiceFiscaleValido, nomeCoerente, cognomeCoerente } from '../../../../lib/codiceFiscale'

import Loading from '../../../../components/Loading'
import FormField, { InputType } from '../../../../components/FormField'

import useIntestatarioById from '../../../../apiHooks/queries/useIntestatarioById'
import useComuni from '../../../../apiHooks/queries/useComuni'
import useCittadinanze from '../../../../apiHooks/queries/useCittadinanze'
import useUpdateIntestatarioById from '../../../../apiHooks/mutations/useUpdateIntestatarioById'
import useCreateIntestatario from '../../../../apiHooks/mutations/useCreateIntestatarioAlunno'

interface IntestatarioProps extends RouteComponentProps {
  intestatarioId?: number
  alunnoId?: number
}

const Intestatario: React.FC<IntestatarioProps> = ({ intestatarioId, alunnoId }) => {
  // ========================
  // LOCAL STATE
  // ========================
  const [comuniKeyword, setComuniKeyword] = useState('')
  const [cittadinanzeKeyword, setCittadinanzeKeyword] = useState('')

  // ========================
  // QUERIES
  // ========================

  // Intestatario
  const { data: existingIntestatario, isFetching, error } = useIntestatarioById(intestatarioId)

  // Comuni
  const { data: comuni = [], status: statusComuni } = useComuni(comuniKeyword)

  // Cittadinanze
  const { data: cittadinanze = [], status: statusCittadinanze } = useCittadinanze(
    cittadinanzeKeyword
  )

  const newIntestatario = {
    nome: '',
    cognome: '',
    codiceFiscale: '',
    cittadinanzaCodiceIstat: '',
    indirizzo: '',
    cap: '',
    comuneCodiceIstat: '',
  }

  const intestatario = !!intestatarioId ? existingIntestatario : newIntestatario

  // FORM
  const { register, handleSubmit, errors, control, reset, getValues } = useForm({
    defaultValues: intestatario,
  })
  useEffect(() => {
    !!intestatarioId && reset(intestatario)
  }, [intestatarioId, intestatario, reset])

  const [updateIntestatario] = useUpdateIntestatarioById(+intestatarioId!, +alunnoId!)
  const [createIntestatario] = useCreateIntestatario(+alunnoId!)

  const onSubmit = (data: any) => {
    const intestatarioToSave = {
      ...data,
      comuneCodiceIstat: data.comuneDati.codiceIstat,
      cittadinanzaCodiceIstat: data.cittadinanzaDati.codiceIstat,
    }

    if (!intestatarioId) {
      createIntestatario({ intestatario: intestatarioToSave })
    } else {
      updateIntestatario({ intestatario: intestatarioToSave })
    }

    navigate(`app/anagrafica/alunno/${alunnoId}/intestatari`)
  }

  if (isFetching) {
    return (
      <div className="flex justify-center py-12">
        <Loading />
      </div>
    )
  }

  if (error) {
    return <span>Si è verificato un errore</span>
  }

  const emailValida = (email: string) => {
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
  }

  return (
    <div className="lg:pl-32 flex flex-col pt-6 pb-20 max-w-3xl">
      <h1 className="text-2xl mb-2 leading-tight">
        {intestatario.nome} {intestatario.cognome}
      </h1>

      <hr className="border-blue-200 border-t-2 mt-4 mb-8"></hr>

      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <section>
          <div className="flex flex-col md:flex-row">
            <div className="flex-1 mb-6">
              <FormField
                fieldName="nome"
                label="Nome"
                type={InputType.Text}
                validation={{
                  required: 'Inserisci il nome',
                  validate: (value: string) =>
                    nomeCoerente(getValues('codiceFiscale'), value) ||
                    'Nome e codice fiscale non coerenti',
                }}
                register={register}
                errors={errors}
              />
            </div>
            <div className="flex-1 mb-6 md:ml-6">
              <FormField
                fieldName="cognome"
                label="Cognome"
                type={InputType.Text}
                validation={{
                  required: 'Inserisci il cognome',
                  validate: (value: string) =>
                    cognomeCoerente(getValues('codiceFiscale'), value) ||
                    'Cognome e codice fiscale non coerenti',
                }}
                register={register}
                errors={errors}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row">
            <div className="flex-1 mb-6">
              <FormField
                fieldName="codiceFiscale"
                label="Codice Fiscale"
                type={InputType.Text}
                validation={{
                  required: 'Inserisci il codice fiscale',
                  validate: (value: string) =>
                    codiceFiscaleValido(value) || 'Il codice fiscale non è valido',
                }}
                register={register}
                errors={errors}
              />
            </div>
            <div className="flex-1 mb-6 md:ml-6">
              <FormField
                fieldName="cittadinanzaDati"
                label="Cittadinanza"
                type={InputType.Select}
                onInputChange={setCittadinanzeKeyword}
                options={cittadinanze}
                isLoading={statusCittadinanze === 'loading'}
                getOptionValue={(v: any) => v.codiceIstat}
                getOptionLabel={(v: any) => v.denominazione}
                isClearable
                validation={{ required: 'Inserisci la cittadinanza' }}
                register={register}
                errors={errors}
                control={control}
              />
            </div>
          </div>
          <div className="mb-6">
            <FormField
              fieldName="indirizzo"
              label="Indirizzo"
              type={InputType.Text}
              validation={{ required: "Inserisci l'indirizzo" }}
              register={register}
              errors={errors}
              control={control}
            />
          </div>

          <div className="flex flex-col md:flex-row">
            <div className="flex-1 mb-6">
              <FormField
                fieldName="cap"
                label="CAP"
                type={InputType.Text}
                validation={{ required: 'Inserisci il CAP' }}
                register={register}
                errors={errors}
                control={control}
              />
            </div>
            <div className="flex-1 mb-6 md:ml-6">
              <FormField
                fieldName="comuneDati"
                label="Comune di residenza"
                type={InputType.Select}
                onInputChange={setComuniKeyword}
                options={comuni}
                isLoading={statusComuni === 'loading'}
                getOptionValue={(v: any) => v.codiceIstat}
                getOptionLabel={(v: any) => v.denominazione}
                isClearable
                validation={{ required: 'Inserisci il comune' }}
                register={register}
                errors={errors}
                control={control}
              />
            </div>
          </div>

          <div className="mb-6">
            <FormField
              fieldName="email"
              label="Email"
              type={InputType.Text}
              validation={{
                validate: (value: string) =>
                  !value || emailValida(value) || "Inserire un'email valida",
              }}
              register={register}
              errors={errors}
              control={control}
            />
          </div>
        </section>

        <button
          type="submit"
          className="block bg-blue-600 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
        >
          {!!intestatarioId ? 'Salva' : 'Crea'}
        </button>
      </form>
    </div>
  )
}

export default Intestatario
