const reactSelectCustomStyles = {
  control: (provided: any, status: any) => {
    if (status.isFocused) {
      return {
        ...provided,
        outline: 'none',
        boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.5)',
        borderColor: '#63b3ed',
        '&:hover': {
          borderColor: '#63b3ed',
        },
      }
    }
    return {
      ...provided,
      borderColor: '#e2e8f0',
      '&:hover': {
        borderColor: '#e2e8f0',
      },
    }
  },
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '5px 10px',
  }),
  singleValue: (provided: any) => provided,
  indicatorSeparator: (provided: any) => ({
    ...provided,
    backgroundColor: '#e2e8f0',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: '#cbd5e0',
    '&:hover': {
      color: '#a0aec0',
    },
  }),
  option: (provided: any, status: any) => {
    if (status.isSelected) {
      return {
        ...provided,
        backgroundColor: '#4299e1',
      }
    }
    return {
      ...provided,
    }
  },
}

export default reactSelectCustomStyles
