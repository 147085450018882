import { useMutation, queryCache } from 'react-query'
import axios from 'axios'
import cogoToast from 'cogo-toast'

interface CreateAlunno {
  annoScolastico: string
  sezioneId: number
  cognome: string
  nome?: string
}

const createAlunno = async (servizioId: number, alunno: CreateAlunno) => {
  const { data } = await axios.post(
    `/servizi/${servizioId}/aggiungi-alunno-fruizione/`,
    alunno
  )
  return data
}

const useCreateAlunno = () => {
  return useMutation(
    ({ servizioId, alunno }: { servizioId: number; alunno: CreateAlunno }) => {
      return createAlunno(servizioId, alunno)
    },
    {
      onSuccess: (data: any) => {
        queryCache.refetchQueries('alunniBySezioneId')
        queryCache.refetchQueries('presenzeAlunniData')
        queryCache.refetchQueries('countAlunniDaControllare', { force: true })
        queryCache.refetchQueries('countFruizioniDaControllare', { force: true })
        cogoToast.success('Alunno creato correttamente.')
      },
      onError: () => {},
    }
  )
}

export default useCreateAlunno
