import { useQuery } from 'react-query'
import axios from 'axios'

interface IPresenzeMese {
  servizioId: number
  scuolaId: string
  mese: number
  anno: number
  annoScolastico: string
}

const getPresenzeMese = async (_: string, { servizioId, scuolaId, mese, anno, annoScolastico }: IPresenzeMese) => {
  const { data } = await axios.get(
    `/servizi/${servizioId}/presenze-mese/?scuolaId=${scuolaId}&mese=${mese}&anno=${anno}&annoScolastico=${annoScolastico}`
  )
  return data
}

const usePresenzeMese = ({ servizioId, scuolaId, mese, anno, annoScolastico }: IPresenzeMese) => {
  return useQuery(
    !!servizioId &&
      !!scuolaId &&
      !!mese &&
      !!anno &&
      !!annoScolastico && ['presenzeMese', { servizioId, scuolaId, mese, anno, annoScolastico }],
    getPresenzeMese
  )
}

export default usePresenzeMese
