import { useQuery } from 'react-query'
import axios from 'axios'

interface IPresenzeCucina {
  servizioId: number
  dataPresenze: string
}

const getPresenzeDataCucina = async (_: string, { servizioId, dataPresenze }: IPresenzeCucina) => {
  const { data } = await axios.get(
    `/servizi/${servizioId}/presenze-totali-scuole/?data=${dataPresenze}`
  )
  return data
}

const usePresenzeDataCucina = ({ servizioId, dataPresenze }: IPresenzeCucina) => {
  return useQuery(
    !!servizioId && !!dataPresenze && ['presenzeDataCucina', { servizioId, dataPresenze }],
    getPresenzeDataCucina
  )
}

export default usePresenzeDataCucina
