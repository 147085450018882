import React from 'react'
import classNames from 'classnames'
import FormField, { InputType } from '../../../../components/FormField'
import { TIPI_FREQUENZA } from '../../../../lib/tipoFrequenzaOptions'

interface FormSingoloServizioProps {
  register: any
  errors: any
  control?: any
  watch?: any
  intestatari: any[]
}

const ComuneBergamoAsilo: React.FC<FormSingoloServizioProps> = ({
  register,
  errors,
  control,
  watch,
  intestatari,
}) => {
  return (
    <div>
      <section>
        {/* <h1 className="text-2xl mb-2 leading-tight">Asilo</h1>
        <hr className="border-blue-200 border-t-2 my-8"></hr> */}
        <div className="text-gray-900 mb-5 font-semibold leading-none">Intestatario bolletta</div>
        <FormField
          fieldName="intestatario"
          type={InputType.Select}
          options={intestatari.map((intestatario: any) => ({
            value: intestatario.id,
            label: `${intestatario.nome} ${intestatario.cognome}`,
          }))}
          isClearable
          control={control}
          register={register}
          validation={{ required: `E' necessario selezionare un intestatario` }}
          errors={errors}
        />
        <div className="text-gray-900 mt-6 mb-5 font-semibold leading-none">Pagamento</div>
        <FormField
          fieldName="pagamentoSdd"
          label="Pagamento SDD"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="codiceSdd"
          label="Codice SDD"
          type={InputType.Text}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="pagamentoSospeso"
          label="Bollette sospese"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">
          Fascia ISEE e quota variabile
        </div>
        <FormField
          fieldName="isee"
          label="Importo ISEE"
          type={InputType.Number}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="isResidente"
          label="Residente (altrimenti fascia max)"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="isDipendenteComune"
          label="Dipendente comune"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="isCasoSociale"
          label="Caso sociale (azzeramento quota variabile)"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">Tipo frequenza</div>
        <FormField
          fieldName="tipoFrequenza"
          label="Tipo Frequenza"
          type={InputType.Select}
          control={control}
          register={register}
          errors={errors}
          className="mb-3"
          options={TIPI_FREQUENZA}
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">Sconto 2°/3° fratello</div>
        <FormField
          fieldName="isSecondoFratello"
          label="Secondo fratello (-30%)"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">Ambientamento</div>
        <FormField
          fieldName="dataInizio"
          label="Data inizio servizio"
          type={InputType.Date}
          register={register}
          validation={{ required: 'Inserisci la data di inizio' }}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="dataFineAmbientamento"
          label="Data fine ambientamento"
          type={InputType.Date}
          register={register}
          // validation={{ required: 'Inserisci la data di fine ambientamento' }}
          errors={errors}
          className="mb-3"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">Asilo gratis</div>
        <FormField
          fieldName="isAsiloGratis"
          label="Beneficia di asilo gratis"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="asiloGratisQuotaGenitore"
          label="Quota in carico a genitore"
          type={InputType.Number}
          register={register}
          errors={errors}
          className="mb-3"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">
          Ritiro temporaneo (min 20, max 50 gg)
        </div>
        <FormField
          fieldName="dataInizioRitiroTemporaneo"
          label="Data inizio ritiro temporaneo"
          type={InputType.Date}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="dataFineRitiroTemporaneo"
          label="Data fine ritiro temporaneo"
          type={InputType.Date}
          register={register}
          errors={errors}
          className="mb-3"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">
          Rinuncia in corso d'anno
        </div>
        <FormField
          fieldName="dataRitiro"
          label="Data ritiro"
          type={InputType.Date}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="dataRitiroBidella"
          label="Data ritiro operatore"
          type={InputType.Date}
          register={register}
          errors={errors}
          className="mb-3"
          labelClassName={classNames({ 'text-pink-500': watch('dataRitiroBidella') })}
          inputClassName={classNames({ 'text-pink-500': watch('dataRitiroBidella') })}
        />
        <FormField
          fieldName="isRitiroConRestituzioneQuota"
          label="Restituzione quota iscrizione"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
        />
      </section>
      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">
          Rimborsi e Maggiorazioni
        </div>
        <FormField
          fieldName="riduzioneMaggiorazioneTemporaneaImporto"
          label="Importo una tantum Prossima bolletta"
          type={InputType.Number}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="riduzioneMaggiorazioneTemporaneaDescrizione"
          label="Descrizione causale una tantum"
          type={InputType.Text}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="riduzioneMaggiorazioneFissaImporto"
          label="Importo mensile"
          type={InputType.Number}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="riduzioneMaggiorazioneFissaDescrizione"
          label="Descrizione causale mensile"
          type={InputType.Text}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="riportoRiduzione"
          label="Riporto precedente"
          type={InputType.Number}
          register={register}
          errors={errors}
          className="mb-3"
        />
      </section>

      <hr className="border-blue-200 border-t-2 my-8"></hr>
      <section>
        <div className="text-gray-900 mb-5 font-semibold leading-none">Note</div>
        <FormField
          fieldName="note"
          label="Note interne"
          type={InputType.Textarea}
          register={register}
          errors={errors}
          className="mb-3"
        />
        <FormField
          fieldName="noteBidella"
          label="Note operatore"
          type={InputType.Textarea}
          register={register}
          errors={errors}
          className="mb-3"
          labelClassName={classNames({ 'text-pink-500': watch('noteBidella') })}
          inputClassName={classNames({ 'text-pink-500': watch('noteBidella') })}
        />
        <FormField
          fieldName="sezioneIdBidella"
          label="Sezione indicata da operatore"
          type={InputType.Select}
          register={register}
          control={control}
          errors={errors}
          isClearable
          className="mb-3"
          labelClassName={classNames({ 'text-pink-500': watch('sezioneIdBidella') })}
          inputStyle={watch('sezioneIdBidella') ? { color: '#ed64a6' } : undefined}
        />
        <FormField
          fieldName="modificatoBidella"
          label="Modificato da operatore"
          type={InputType.Boolean}
          register={register}
          errors={errors}
          className="mb-3"
          labelClassName={classNames({ 'text-pink-500': watch('modificatoBidella') })}
          inputClassName={classNames({ 'text-pink-500': watch('modificatoBidella') })}
        />
      </section>

      <button
        type="submit"
        className="block bg-blue-600 hover:bg-blue-700 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
      >
        Salva
      </button>
    </div>
  )
}

export default ComuneBergamoAsilo
