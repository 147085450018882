import React, { useRef } from 'react'
import Select from 'react-select'
import { Controller } from 'react-hook-form'
import classNames from 'classnames'

import reactSelectCustomStyles from './reactSelectCustomStyles'

export enum InputType {
  Text = 'text',
  Number = 'number',
  Password = 'password',
  Date = 'date',
  Textarea = 'textarea',
  Boolean = 'boolean',
  Select = 'select',
  WeekDay = 'weekDay',
}

interface FieldProps {
  fieldName: string
  label?: string
  type: InputType
  validation?: any
  register?: any
  errors: any
  control?: any
  className?: string
  inputClassName?: string
  labelClassName?: string
  inputStyle?: object
  rows?: number
  options?: any[]
  isLoading?: boolean
  defaultValue?: any
  isClearable?: boolean
  onInputChange?: (input: string) => void
  getOptionValue?: (value: any) => string
  getOptionLabel?: (value: any) => string
}

const FormField: React.FC<FieldProps> = ({
  fieldName,
  label,
  type,
  rows,
  validation,
  register,
  errors,
  control,
  className,
  inputClassName,
  labelClassName,
  inputStyle,
  ...rest
}) => {
  const cssClass = className ? { className } : {}
  const selectRef = useRef<any>(null)

  let selectCustomStyles = reactSelectCustomStyles

  if (type === InputType.Select && inputStyle) {
    selectCustomStyles = {
      ...selectCustomStyles,
      singleValue: (provided: any) => ({
        ...provided,
        ...inputStyle,
      }),
    }
  }

  return (
    <div {...cssClass}>
      {(type === InputType.Text ||
        type === InputType.Number ||
        type === InputType.Password ||
        type === InputType.Date) && (
        <div>
          {label && (
            <label
              htmlFor={fieldName}
              className={classNames(
                'block text-sm leading-5 font-medium text-gray-600',
                labelClassName
              )}
            >
              {label}
            </label>
          )}
          <input
            className={classNames('mt-1 form-input w-full', inputClassName)}
            id={fieldName}
            name={fieldName}
            type={type}
            ref={register(validation)}
          />
        </div>
      )}
      {type === InputType.Textarea && (
        <div>
          {label && (
            <label
              htmlFor={fieldName}
              className={classNames(
                'block text-sm leading-5 font-medium text-gray-600',
                labelClassName
              )}
            >
              {label}
            </label>
          )}
          <textarea
            className={classNames('mt-1 form-input w-full', inputClassName)}
            id={fieldName}
            name={fieldName}
            rows={rows || 3}
            ref={register(validation)}
          />
        </div>
      )}
      {type === InputType.Boolean && (
        <label className={classNames('flex items-center')}>
          <input
            type="checkbox"
            className={classNames('form-checkbox', inputClassName)}
            id={fieldName}
            name={fieldName}
            ref={register(validation)}
          />
          <span
            className={classNames(
              'ml-2 text-sm leading-5 font-medium text-gray-600',
              labelClassName
            )}
          >
            {label}
          </span>
        </label>
      )}
      {type === InputType.Select && (
        <div>
          {label && (
            <label
              htmlFor={fieldName}
              className={classNames(
                'block text-sm leading-5 font-medium text-gray-600',
                labelClassName
              )}
            >
              {label}
            </label>
          )}
          <Controller
            as={<Select ref={selectRef} />}
            className={classNames('mt-1 w-full', inputClassName)}
            name={fieldName}
            rules={validation}
            control={control}
            styles={selectCustomStyles}
            loadingMessage={() => 'Caricamento...'}
            noOptionsMessage={() => 'Nessuna opzione disponibile'}
            placeholder="Seleziona..."
            // onFocus={() => {
            //   selectRef.current?.focus()
            // }}
            {...rest}
          />
        </div>
      )}
      {type === InputType.WeekDay && (
        <div className="flex flex-col items-center mr-5">
          <label
            htmlFor={`${fieldName}`}
            className={classNames(
              'text-sm leading-5 font-medium text-gray-600 mb-1',
              labelClassName
            )}
          >
            {label}
          </label>
          <input
            type="checkbox"
            className={classNames('form-checkbox', inputClassName)}
            id={`${fieldName}`}
            name={`${fieldName}`}
            ref={register()}
          />
        </div>
      )}
      {errors[fieldName] && <div className="text-xs text-red-500">{errors[fieldName].message}</div>}
    </div>
  )
}

// className="mt-1 appearance-none border border-gray-200 rounded-md w-full py-2 px-4 text-gray-900 leading-tight focus:outline-none focus:shadow-outline focus:border-indigo-300"

// className="mt-1 bg-indigo-100 appearance-none border-2 border-indigo-100 rounded w-full py-2 px-4 text-indigo-700 leading-tight focus:outline-none focus:bg-white focus:border-indigo-500"

export default FormField
