import React, { useState, useEffect } from 'react'
import Loader from 'react-loader-spinner'
import classNames from 'classnames'

interface LoadingProps {
  size?: number
  className?: string
  type?:
    | 'Oval'
    | 'Audio'
    | 'BallTriangle'
    | 'Bars'
    | 'Circles'
    | 'Grid'
    | 'Hearts'
    | 'Puff'
    | 'Rings'
    | 'TailSpin'
    | 'ThreeDots'
    | 'Watch'
    | 'RevolvingDot'
    | 'Triangle'
    | 'Plane'
    | 'MutatingDots'
    | 'None'
    | 'NotSpecified'
    | undefined
}

const Loading: React.FC<LoadingProps> = ({ size = 100, className, type = 'Oval' }) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), 400)
    return () => clearTimeout(timer)
  }, [])

  return (
    <div className={classNames('flex justify-center items-center', className)}>
      {show && <Loader type={type} color="#63b3ed" height={size} width={size} />}
    </div>
  )
}

export default Loading
