import { useQuery } from 'react-query'
import axios from 'axios'

const getIntestatarioById = async (_: string, intestatarioId: number) => {
  const { data } = await axios.get(`/intestatari/${intestatarioId}`)
  return data
}

const useIntestatarioById = (intestatarioId?: number) => {
  return useQuery(!!intestatarioId && ['intestatarioById', intestatarioId], getIntestatarioById)
}

export default useIntestatarioById
