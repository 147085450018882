import { useQuery } from 'react-query'
import axios from 'axios'

const getComuni = async (_: string, keyword: string, limit: number) => {
  const { data } = await axios.get(`/comuni?search=${keyword}&limit=${limit}`)
  return data.results
}

const useComuni = (keyword: string) => {
  return useQuery(['comuni', keyword, 10], getComuni, {
    // 30 minuti
    staleTime: 30 * 60 * 1000,
  })
}

export default useComuni
