import { useQuery } from 'react-query'
import axios from 'axios'

interface GetDateEmissioneArgs {
  servizioId: number
  annoScolastico: string
}

const getDateEmissioneByIdByAnno = async (_: string, { servizioId, annoScolastico }: GetDateEmissioneArgs) => {
  const { data } = await axios.get(`/servizi/${servizioId}/date-emissione/?annoScolastico=${annoScolastico}`)
  return data
}

const useDateEmissioneByIdByAnno = ({ servizioId, annoScolastico }: GetDateEmissioneArgs) => {
  return useQuery(
    !!servizioId && !!annoScolastico && ['dateEmissioneByIdByAnno', { servizioId, annoScolastico }],
    getDateEmissioneByIdByAnno
  )
}

export default useDateEmissioneByIdByAnno
