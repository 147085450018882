import { useQuery } from 'react-query'
import axios from 'axios'

interface IFruizioniDaControllare {
  annoScolastico: string
  servizioId: number
  scuolaId?: number
}

const getFruizioniDaControllare = async (
  _: string,
  { annoScolastico, servizioId, scuolaId }: IFruizioniDaControllare
) => {
  const scuolaIdQuery = scuolaId ? `&scuolaId=${scuolaId}` : ''
  const { data } = await axios.get(
    `/fruizioni/da-controllare?annoScolastico=${annoScolastico}&servizioId=${servizioId}&limit=10000${scuolaIdQuery}`
  )
  return data.results
}

const useFruizioniDaControllare = ({
  annoScolastico,
  servizioId,
  scuolaId,
}: IFruizioniDaControllare) => {
  return useQuery(
    !!annoScolastico && !!servizioId && ['fruizioniDaControllare', { annoScolastico, servizioId, scuolaId }],
    getFruizioniDaControllare
  )
}

export default useFruizioniDaControllare
