import React, { useState, useContext } from 'react'
import { RouteComponentProps, Link } from '@reach/router'
import classNames from 'classnames'
import InlineEdit, { InputType } from 'riec'
import { FiEdit } from 'react-icons/fi'

import useScuoleByServizioId from '../../../apiHooks/queries/useScuoleByServizioId'
import useSezioniByScuolaId from '../../../apiHooks/queries/useSezioniByScuolaId'
import useAlunniBySezioneId from '../../../apiHooks/queries/useAlunniBySezioneId'
import useUpdateSezioneById from '../../../apiHooks/mutations/useUpdateSezioneById'
import useCreateSezione from '../../../apiHooks/mutations/useCreateSezione'
import useCreateAlunno from '../../../apiHooks/mutations/useCreateAlunno'

import AnnoScolasticoContext from '../../../context/AnnoScolasticoContext'
import ServizioContext from '../../../context/ServizioContext'

import Loading from '../../../components/Loading'

const Elenco: React.FC<RouteComponentProps> = () => {

  //===================
  // CONTEXT
  //===================
  const { annoScolastico } = useContext(AnnoScolasticoContext)
  const { servizioId } = useContext(ServizioContext)

  //===================
  // LOCAL STATE
  //===================
  const [keywordScuola, setKeywordScuola] = useState('')
  const [keywordClasse, setKeywordClasse] = useState('')
  const [keywordAlunno, setKeywordAlunno] = useState('')
  const [nuovaSezioneClasse, setNuovaSezioneClasse] = useState('')
  const [nuovaSezioneSezione, setNuovaSezioneSezione] = useState('')
  const [creaNuovaSezione, setCreaNuovaSezione] = useState(false)
  const [nuovoAlunnoCognome, setNuovoAlunnoCognome] = useState('')
  const [nuovoAlunnoNome, setNuovoAlunnoNome] = useState('')
  const [creaNuovoAlunno, setCreaNuovoAlunno] = useState(false)
  const [selectedScuola, setSelectedScuola] = useState(0)
  const [selectedClasse, setSelectedClasse] = useState(0)

  //===================
  // HOOKS QUERIES
  //===================
  const { data: scuole, isFetching: isFetchingScuole } = useScuoleByServizioId(servizioId)
  const { data: sezioni, isFetching: isFetchingSezioni } = useSezioniByScuolaId({
    scuolaId: selectedScuola,
    annoScolastico,
  })
  const { data: alunni, isFetching: isFetchingAlunni } = useAlunniBySezioneId(selectedClasse)

  //===================
  // HOOKS MUTATIONS
  //===================
  const [updateSezione] = useUpdateSezioneById(selectedClasse)
  const [createSezione] = useCreateSezione()
  const [createAlunno] = useCreateAlunno()

  const search = (keywords: string, fields: string[]) => (item: {
    [key: string]: string
  }) =>
    keywords
      .split(' ')
      .every((term) =>
        fields.some((attribute: string) =>
          item[attribute] && item[attribute].toLowerCase().includes(term)
        )
      )

  const handleCreateSezione = () => {
    createSezione({
      classe: nuovaSezioneClasse,
      sezione: nuovaSezioneSezione,
      annoScolastico,
      scuolaId: selectedScuola,
    })
    setCreaNuovaSezione(false)
  }

  const handleCreateAlunno = () => {
    createAlunno({
      servizioId,
      alunno: {
        cognome: nuovoAlunnoCognome,
        nome: nuovoAlunnoNome,
        sezioneId: selectedClasse,
        annoScolastico,
      },
    })
    setCreaNuovoAlunno(false)
  }

  return (
    <div className="pb-20 min-h-screen">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="pt-4 flex flex-col sm:flex-row">
          <div className="scuola w-1/3 pr-6">
            <input
              type="text"
              className="py-2 px-4 w-full text-gray-700 rounded-lg border-2 border-gray-300 focus:border-2 focus:outline-none focus:border-blue-500 text-sm mb-4"
              placeholder="Scuola..."
              value={keywordScuola}
              onChange={(e) => setKeywordScuola(e.target.value)}
            />
            <div>
              {isFetchingScuole && <Loading />}
              {scuole &&
                scuole
                  .filter(search(keywordScuola, ['nome', 'tipo']))
                  .map((s: any) => (
                    <div
                      key={s.id}
                      className={classNames(
                        'text-md text-blue-900 px-4 py-1 rounded-lg cursor-pointer mb-1',
                        { 'bg-blue-200 font-medium': selectedScuola === s.id }
                      )}
                      onClick={(e) => {
                        setSelectedScuola(s.id)
                        setSelectedClasse(0)
                      }}
                    >
                      <span className="capitalize">
                        {s.tipo} {s.nome}
                      </span>
                    </div>
                  ))}
            </div>
          </div>
          {!!selectedScuola && (
            <>
              <div className="classe w-1/3 px-6 border-l-2 border-blue-200">
                <input
                  type="text"
                  className="py-2 px-4 w-full text-gray-700 rounded-lg border-2 border-gray-300 focus:border-2 focus:outline-none focus:border-blue-500 text-sm mb-4"
                  placeholder="Classe..."
                  value={keywordClasse}
                  onChange={(e) => setKeywordClasse(e.target.value)}
                />
                <div>
                  {!creaNuovaSezione && (
                    <button
                      type="button"
                      className="block w-full mb-4 bg-green-600 hover:bg-green-500 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                      onClick={() => setCreaNuovaSezione(true)}
                    >
                      Crea nuova
                    </button>
                  )}
                  {creaNuovaSezione && (
                    <div className="flex mb-4">
                      <input
                        type="text"
                        className="form-input w-0 flex-1 mr-2"
                        value={nuovaSezioneClasse}
                        onChange={(e) => setNuovaSezioneClasse(e.target.value)}
                      />
                      <input
                        type="text"
                        className="form-input w-0 flex-1 mr-2"
                        value={nuovaSezioneSezione}
                        onChange={(e) => setNuovaSezioneSezione(e.target.value)}
                      />
                      <button
                        type="button"
                        className="flex-1 block bg-green-600 hover:bg-green-500 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-3 rounded transition-colors duration-150"
                        onClick={handleCreateSezione}
                      >
                        Crea
                      </button>
                    </div>
                  )}
                  {isFetchingSezioni && <Loading />}
                  {sezioni &&
                    sezioni
                      .filter(search(keywordClasse, ['classe', 'sezione']))
                      .map((c: any) => (
                        <div
                          key={c.id}
                          className={classNames(
                            'text-md text-blue-900 px-4 py-1 rounded-lg cursor-pointer mb-1',
                            {
                              'bg-blue-200 font-medium':
                                selectedClasse === c.id,
                            }
                          )}
                          onClick={(e) => setSelectedClasse(c.id)}
                        >
                          {selectedClasse === c.id ? (
                            <div className="flex justify-between items-center">
                              <div className="flex items-center">
                                <InlineEdit
                                  type={InputType.Text}
                                  value={c.classe}
                                  onChange={(v) => {
                                    updateSezione({ classe: v })
                                  }}
                                  viewClass="mr-1"
                                  render={(v) => (
                                    <span>
                                      {v}
                                      <sup>a</sup>
                                    </span>
                                  )}
                                />
                                <InlineEdit
                                  type={InputType.Text}
                                  value={c.sezione}
                                  onChange={(v) => {
                                    updateSezione({ sezione: v })
                                  }}
                                />
                              </div>

                              <button className="text-blue-400">
                                <FiEdit />
                              </button>
                            </div>
                          ) : (
                            <span>
                              {c.classe}
                              <sup>a</sup> {c.sezione}
                            </span>
                          )}
                        </div>
                      ))}
                </div>
              </div>

              {!!selectedClasse && (
                <div className="alunno w-1/3 pl-6 border-l-2 border-blue-200">
                  <input
                    type="text"
                    className="py-2 px-4 w-full text-gray-700 rounded-lg border-2 border-gray-300 focus:border-2 focus:outline-none focus:border-blue-500 text-sm mb-4"
                    placeholder="Nome cognome..."
                    value={keywordAlunno}
                    onChange={(e) => setKeywordAlunno(e.target.value)}
                  />
                  <div>
                    {!creaNuovoAlunno && (
                      <button
                        type="button"
                        className="block w-full mb-4 bg-green-600 hover:bg-green-500 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-5 rounded transition-colors duration-150"
                        onClick={() => setCreaNuovoAlunno(true)}
                      >
                        Crea nuovo
                      </button>
                    )}
                    {creaNuovoAlunno && (
                      <div className="flex mb-4">
                        <input
                          type="text"
                          className="form-input w-0 flex-1 mr-2"
                          value={nuovoAlunnoCognome}
                          placeholder="Cognome"
                          onChange={(e) =>
                            setNuovoAlunnoCognome(e.target.value)
                          }
                        />
                        <input
                          type="text"
                          className="form-input w-0 flex-1 mr-2"
                          value={nuovoAlunnoNome}
                          placeholder="Nome"
                          onChange={(e) => setNuovoAlunnoNome(e.target.value)}
                        />
                        <button
                          type="button"
                          className="flex-1 block bg-green-600 hover:bg-green-500 hover:shadow-xl focus:shadow-outline focus:outline-none text-white text-sm leading-5 py-2 px-3 rounded transition-colors duration-150"
                          onClick={handleCreateAlunno}
                        >
                          Crea
                        </button>
                      </div>
                    )}
                    {isFetchingAlunni && <Loading />}
                    {alunni &&
                      alunni
                        .filter(search(keywordAlunno, ['cognome', 'nome']))
                        .map((a: any) => (
                          <Link to={`alunno/${a.id}`} key={a.id}>
                            <div className="text-md text-gray-800 capitalize px-4 py-1 rounded-lg mb-1">
                              {a.cognome.toLowerCase()}{' '}
                              {a.nome && a.nome.toLowerCase()}
                            </div>
                          </Link>
                        ))}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Elenco
