import React, { useState, useEffect } from 'react'
import { RouteComponentProps, Router, navigate } from '@reach/router'
import moment from 'moment'

import AnnoScolasticoContext from '../context/AnnoScolasticoContext'
import ServizioContext from '../context/ServizioContext'
import useProfile from '../apiHooks/queries/useProfile'

import Header from './Header'
import Footer from './Footer'

import Home from '../pages/home'
import Anagrafica from '../pages/anagrafica'
import Ricerche from '../pages/anagrafica/liste'
import Lista from '../pages/anagrafica/liste/Elenco'
import Ricerca from '../pages/anagrafica/liste/Ricerca'
import AlunniDaControllare from '../pages/anagrafica/liste/AlunniDaControllare'
import FruizioniDaControllare from '../pages/anagrafica/liste/FruizioniDaControllare'
import Alunno from '../pages/anagrafica/alunno'
import Presenze from '../pages/presenze'
import PresenzeGiornaliere from '../pages/presenze/giornaliere2'
import PresenzeMensili from '../pages/presenze/mensili'
import PresenzeCucina from '../pages/presenze/cucina'
import AvvisiPagamento from '../pages/avvisiPagamento'
import Documenti from '../pages/avvisiPagamento/documenti'
import PagamentiSolleciti from '../pages/avvisiPagamento/pagamenti-solleciti'
import ParametriServizio from '../pages/parametriServizio'

const LoggedApp: React.FC<RouteComponentProps> = ({ children }) => {
  //==============================
  // RECUPERIAMO UTENTE
  // =============================
  const token = localStorage.getItem('token')
  const { data: user } = useProfile(token)

  //==============================
  // ANNO SCOLASTICO PER CONTEXT
  // =============================
  const meseCorrente = moment().month()
  const annoCorrente = moment().year()
  let annoScolasticoCorrente = ''

  if (meseCorrente > 8) {
    annoScolasticoCorrente = `${annoCorrente}-${annoCorrente + 1}`
  } else {
    annoScolasticoCorrente = `${annoCorrente - 1}-${annoCorrente}`
  }

  const ultimoAnnoUsato = localStorage.getItem('annoScolastico')

  const [annoScolastico, setAnnoScolastico] = useState<string>(
    ultimoAnnoUsato || annoScolasticoCorrente
  )

  const handleChangeAnnoScolastico = (anno: string) => {
    navigate('/app').then(() => {
      setAnnoScolastico(anno)
      localStorage.setItem('annoScolastico', anno)
    })
  }

  //==============================
  // SERVIZIO PER CONTEXT
  // =============================
  const ultimoServizioUsato = localStorage.getItem('servizioId')
  const [servizioId, setServizioId] = useState<number>(
    ultimoServizioUsato ? +ultimoServizioUsato : 0
  )

  const handleChangeServizioId = (servizioId: number) => {
    navigate('/app').then(() => {
      setServizioId(servizioId)
      localStorage.setItem('servizioId', servizioId + '')
    })
  }

  useEffect(() => {
    if (user && user.servizi && user.servizi.length > 0) {
      setServizioId(
        ultimoServizioUsato ? +ultimoServizioUsato : user.servizi[0]
      )
    }
  }, [user])

  if (!user) {
    // Non dovresti essere qui...
    return null
  }

  return (
    <div className="logged-app">
      <AnnoScolasticoContext.Provider
        value={{ annoScolastico, handleChangeAnnoScolastico }}
      >
        <ServizioContext.Provider
          value={{ servizioId, handleChangeServizioId }}
        >
          <Header />
          <div
            className="logged"
            style={{
              backgroundColor: 'hsla(208, 85%, 98%, 1)',
              minHeight: 'calc(100vh - 223px)',
            }}
          >
            <Router primary={false}>
              <Home path="/" />
              <Anagrafica path="anagrafica">
                <Ricerche path="/">
                  <Lista path="/" />
                  <Ricerca path="ricerca" />
                  <AlunniDaControllare path="anomalie-alunni" />
                  <FruizioniDaControllare path="anomalie-fruizioni" />
                </Ricerche>
                <Alunno path="alunno/:id/*"></Alunno>
              </Anagrafica>
              <Presenze path="presenze">
                <PresenzeGiornaliere path="/" />
                <PresenzeMensili path="mensili" />
                <PresenzeCucina path="cucina" />
              </Presenze>
              <AvvisiPagamento path="avvisi-pagamento">
                <Documenti path="/" />
                <PagamentiSolleciti path="pagamenti-solleciti" />
              </AvvisiPagamento>
              <ParametriServizio path="parametri-servizio" />
            </Router>
          </div>
          <Footer />
        </ServizioContext.Provider>
      </AnnoScolasticoContext.Provider>
    </div>
  )
}

export default LoggedApp
