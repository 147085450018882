import { useQuery } from 'react-query'
import axios from 'axios'

interface IPresenzeAlunni {
  sezioneId?: number
  servizioId: number
  dataPresenze: string
  mostraRitirati?: boolean
}

const getPresenzeAlunniData = async (_: string, { sezioneId, servizioId, dataPresenze, mostraRitirati }: IPresenzeAlunni) => {
  const { data } = await axios.get(`/sezioni/${sezioneId}/presenze-alunni/${servizioId}/?data=${dataPresenze}&mostra_ritirati=${mostraRitirati}`)
  return data.results
}

const usePresenzeAlunniData = ({ sezioneId, servizioId, dataPresenze, mostraRitirati = false }: IPresenzeAlunni) => {
  return useQuery(!!sezioneId && !!servizioId && !!dataPresenze && ['presenzeAlunniData', { sezioneId, servizioId, dataPresenze, mostraRitirati}], getPresenzeAlunniData)
}

export default usePresenzeAlunniData
