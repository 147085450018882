import React from 'react'
import { RouteComponentProps } from '@reach/router'

const ParametriServizio: React.FC<RouteComponentProps> = () => {
  return (
    <div className="max-w-5xl mx-auto py-12">
      <div className="p-6 bg-blue-100 border border-blue-200 mb-3">
        Parametri servizio
      </div>
      <div className="p-6 bg-blue-100 border border-blue-200 mb-3">
        Gestione giorni festivi
      </div>
      <div className="p-6 bg-blue-100 border border-blue-200">
        Gestione giorni non erogazione
      </div>
      
    </div>
  )
}

export default ParametriServizio
