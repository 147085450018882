import React from 'react'
import { RouteComponentProps, Link } from '@reach/router'

const isActive = ({ isCurrent }: { isCurrent: boolean }) => {
  return isCurrent
    ? {
        className: 'mr-4 py-1 px-3 bg-blue-200 border-blue-200 text-blue-800 text-sm font-medium rounded'
      }
    : {}
}

const Presenze: React.FC<RouteComponentProps> = ({ children }) => {
  return (
    <div>
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-6 flex">
          <Link
            to="./"
            className="mr-4 py-1 px-3 border border-gray-100 hover:border-blue-200 hover:bg-white text-gray-700 text-sm rounded transition-colors duration-150"
            getProps={isActive}
          >
            Giornaliere
          </Link>
          <Link
            to="mensili"
            className="mr-4 py-1 px-3 border border-gray-100 hover:border-blue-200 hover:bg-white text-gray-700 text-sm rounded transition-colors duration-150"
            getProps={isActive}
          >
            Mensili
          </Link>
          <Link
            to="cucina"
            className="mr-4 py-1 px-3 border border-gray-100 hover:border-blue-200 hover:bg-white text-gray-700 text-sm rounded transition-colors duration-150"
            getProps={isActive}
          >
            Cucina
          </Link>
        </div>
      </div>
      {children}
    </div>
  )
}

export default Presenze
