import React, { useState, useContext } from 'react'
import { RouteComponentProps, Link } from '@reach/router'
import classNames from 'classnames'

import AnnoScolasticoContext from '../../../context/AnnoScolasticoContext'

import useAlunni from '../../../apiHooks/queries/useAlunni'
import { getSesso } from '../../../lib/codiceFiscale'
import useDebounce from '../../../lib/useDebounce'

import Loading from '../../../components/Loading'

import imgAlunno from '../../../images/alunno.svg'
import imgAlunna from '../../../images/alunna.svg'

const Ricerca: React.FC<RouteComponentProps> = () => {
  //===================
  // CONTEXT
  //===================
  const { annoScolastico } = useContext(AnnoScolasticoContext)

  //===================
  // LOCAL STATE
  //===================
  const [cognome, setCognome] = useState('')
  const [nome, setNome] = useState('')
  const [codiceFiscale, setCodiceFiscale] = useState('')
  const [soloAnnoCorrente, setSoloAnnoCorrente] = useState(true)

  const cognomeDebounced: string = useDebounce(cognome, 500)
  const nomeDebounced: string = useDebounce(nome, 500)
  const codiceFiscaleDebounced: string = useDebounce(codiceFiscale, 500)

  //===================
  // HOOKS QUERIES
  //===================
  const { data: alunni, isFetching } = useAlunni({
    cognome: cognomeDebounced,
    nome: nomeDebounced,
    codiceFiscale: codiceFiscaleDebounced,
    annoScolastico: soloAnnoCorrente ? annoScolastico : '',
  })

  //===================
  // EVENT HANDLERS
  //===================
  const handleChangeSoloAnnoCorrente = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSoloAnnoCorrente(event.target.checked)
  }

  return (
    <div className="pb-20">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="pt-4">
          <div className="flex">
            <input
              type="text"
              className="py-2 px-4 w-40 text-gray-700 text-sm rounded-lg border-2 border-gray-300 focus:border-2 focus:outline-none focus:border-blue-500"
              placeholder="Cognome"
              value={cognome}
              onChange={(e) => setCognome(e.target.value)}
            />
            <input
              type="text"
              className="ml-6 py-2 px-4 w-40 text-gray-700 text-sm rounded-lg border-2 border-gray-300 focus:border-2 focus:outline-none focus:border-blue-500"
              placeholder="Nome"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
            <input
              type="text"
              className="ml-6 py-2 px-4 w-40 text-gray-700 text-sm rounded-lg border-2 border-gray-300 focus:border-2 focus:outline-none focus:border-blue-500"
              placeholder="Codice fiscale"
              value={codiceFiscale}
              onChange={(e) => setCodiceFiscale(e.target.value)}
            />
            <label className="ml-6 flex items-center font-normal text-gray-600">
              <input
                type="checkbox"
                className="form-checkbox"
                checked={soloAnnoCorrente}
                onChange={handleChangeSoloAnnoCorrente}
              />
              <span className="ml-2">Solo anno {annoScolastico}</span>
            </label>
          </div>
          {isFetching && <Loading className="py-12" />}
          <div className="lg:flex lg:-mx-4 flex-wrap">
            {alunni &&
              alunni.map((alunnoRicerca: any) => {
                const { alunno, sezione } = alunnoRicerca
                const sesso = alunno && getSesso(alunno.codiceFiscale || '')

                return (
                  <Link
                    key={alunno.id}
                    to={`../alunno/${alunno.id}`}
                    className="lg:w-1/2 lg:px-4 mt-6"
                  >
                    <div className="bg-white shadow hover:shadow-lg border-gray-200 rounded-lg py-5 px-5 flex justify-between items-start">
                      <div className="flex">
                        <img
                          src={sesso === 'F' ? imgAlunna : imgAlunno}
                          alt={`${alunno.cognome} ${alunno.nome}`}
                          className={classNames(
                            'w-16 h-16 p-1 rounded-full mr-6 border',
                            { 'bg-red-100 border-red-200': sesso === 'F' },
                            {
                              'bg-blue-100 border-blue-200': sesso !== 'F',
                            }
                          )}
                        />
                        <div className="flex flex-col items-start">
                          <h2 className="text-lg mb-2 font-semibold">
                            {alunno.cognome} {alunno.nome}
                          </h2>

                          <div className="text-gray-600">
                            <span className="capitalize">
                              {sezione.scuolaTipo}
                            </span>{' '}
                            {sezione.scuolaNome}
                          </div>

                          <div className="text-purple-500 text-sm font-semibold">
                            {sezione.classe}
                            <sup>a</sup> {sezione.sezione}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          className={classNames(
                            'py-1 px-2 rounded text-sm font-medium whitespace-no-wrap mb-2',
                            {
                              'bg-blue-100 text-blue-600':
                                sezione.annoScolastico === annoScolastico,
                            },
                            {
                              'bg-gray-100 text-gray-600':
                                sezione.annoScolastico !== annoScolastico,
                            }
                          )}
                        >
                          {sezione.annoScolastico}
                        </div>
                      </div>
                    </div>
                  </Link>
                )
              })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Ricerca
