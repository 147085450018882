import { useQuery } from 'react-query'
import axios from 'axios'

const getProfile = async () => {
  const { data } = await axios.get(`/profile/`)
  const {
    username,
    operatore: { nome, cognome, ruolo, servizi },
  } = data
  return {
    username,
    nome,
    cognome,
    ruolo,
    servizi
  }
}

const useProfile = (token: string | null) => {
  return useQuery(token && 'profile', getProfile, {
    // 30 minuti
    staleTime: 30 * 60 * 1000
  })
}

export default useProfile
