import moment from 'moment'

// TIPO PRESENZA
export type TipoPresenza = 'A' | 'P' | 'B' | 'M'

// IS PRESENTE
export const isPresente = (tipoPresenza: TipoPresenza) =>
  tipoPresenza === 'P' || tipoPresenza === 'B' || tipoPresenza === 'M'

// IS ASSENTE
export const isAssente = (tipoPresenza: TipoPresenza) => tipoPresenza === 'A'

const isFuturo = (data: string) => {
  return moment(data, 'YYYY-MM-DD', true).isAfter(moment())
}

const isPrimaDiInizioServizio = (data: string, dataInizio: string) => {
  return (
    dataInizio && moment(data, 'YYYY-MM-DD', true).isBefore(moment(dataInizio, 'YYYY-MM-DD', true))
  )
  // or prima di anno scolastico corrente
}

const isDopoFineServizio = (data: string, dataFine: string) => {
  return dataFine && moment(data, 'YYYY-MM-DD', true).isAfter(moment(dataFine, 'YYYY-MM-DD', true))
  // or dopo anno scolastico corrente
}

// IS CHIUSURA
// (comprende sia festività che chiusura per scioperi, ...)
export const isChiusura = (giorniNonErogazione: any, data: string) => {
  return giorniNonErogazione.find((g: any) => g.data === data)
}

// IS CHIUSURA NO FESTIVITÀ
export const isChiusuraNoFestivita = (giorniNonErogazione: any, data: string) => {
  const giorniChiusuraNoFesta = giorniNonErogazione.filter((g: any) => !g.isFestivita)
  return giorniChiusuraNoFesta.find((g: any) => g.data === data)
}

// IS FESTIVITÀ
export const isFestivita = (giorniNonErogazione: any, data: string) => {
  const giorniFesta = giorniNonErogazione.filter((g: any) => !!g.isFestivita)
  return giorniFesta.find((g: any) => g.data === data)
}

// IS WEEKEND
export const isWeekend = (data: string) => {
  const weekDay: number = moment(data, 'YYYY-MM-DD', true).weekday()
  return weekDay === 5 || weekDay === 6
}

// IS NON EROGAZIONE
export const isNonErogazione = (giorniNonErogazione: any, data: string) => {
  return isChiusura(giorniNonErogazione, data) || isWeekend(data)
}

// IS ERRORE SEGNATO
export const isErroreSegnato = (
  tipoPresenza: TipoPresenza,
  giorniNonErogazione: any,
  fruizione: any,
  data: string
) => {
  return (
    tipoPresenza &&
    (isNonErogazione(giorniNonErogazione, data) ||
      isPrimaDiInizioServizio(data, fruizione.dataInizio) ||
      isDopoFineServizio(data, fruizione.dataRitiro))
  )
}

// IS ERRORE DIMENTICATO
export const isErroreDimenticato = (
  tipoPresenza: TipoPresenza,
  giorniNonErogazione: any,
  fruizione: any,
  data: string
) => {
  return (
    !tipoPresenza &&
    !isNonErogazione(giorniNonErogazione, data) &&
    !isFuturo(data) &&
    !isPrimaDiInizioServizio(data, fruizione.dataInizio) &&
    !isDopoFineServizio(data, fruizione.dataRitiro)
  )
}

// IS ERRORE
export const isErrore = (
  data: string,
  tipoPresenza: TipoPresenza,
  giorniNonErogazione: any,
  fruizione: any
) => {
  if (!data) {
    return false
  }
  return (
    isErroreSegnato(tipoPresenza, giorniNonErogazione, fruizione, data) ||
    isErroreDimenticato(tipoPresenza, giorniNonErogazione, fruizione, data)
  )
}

// IS WARNING
export const isWarning = (data: string, tipoPresenza: TipoPresenza, fruizione: any) => {
  if (!data) {
    return false
  }
  const giornoSettimana = moment(data, 'YYYY-MM-DD', true).format('ddd')
  const fruizionePrevista = fruizione[giornoSettimana]
  return !fruizionePrevista && isPresente(tipoPresenza)
}
