import { useQuery } from 'react-query'
import axios from 'axios'

interface IAlunniDaControllare {
  annoScolastico: string
  scuolaId?: number
}

const getAlunniDaControllare = async (
  _: string,
  { annoScolastico, scuolaId }: IAlunniDaControllare
) => {
  const scuolaIdQuery = scuolaId ? `&scuolaId=${scuolaId}` : ''
  const { data } = await axios.get(
    `/alunni/da-controllare?annoScolastico=${annoScolastico}${scuolaIdQuery}`
  )
  return data.results
}

const useAlunniDaControllare = ({
  annoScolastico,
  scuolaId,
}: IAlunniDaControllare) => {
  return useQuery(
    !!annoScolastico && ['alunniDaControllare', { annoScolastico, scuolaId }],
    getAlunniDaControllare
  )
}

export default useAlunniDaControllare
