import React, { useState, useContext, useEffect } from 'react'
import { RouteComponentProps } from '@reach/router'
import InlineEdit, { InputType } from 'riec'
import classNames from 'classnames'
import moment from 'moment'
import { FiAlertTriangle, FiCheck } from 'react-icons/fi'

import Loading from '../../components/Loading'
import ServizioContext from '../../context/ServizioContext'
import AnnoScolasticoContext from '../../context/AnnoScolasticoContext'
import usePresenzeDataCucina from '../../apiHooks/queries/usePresenzeDataCucina'

interface Presenza {
  [key: string]: number
}

const totaliIniziali = { P: 0, A: 0, B: 0, M: 0 }

const PresenzeCucina: React.FC<RouteComponentProps> = () => {
  //===================
  // CONTEXT
  //===================
  const { servizioId } = useContext(ServizioContext)
  const { annoScolastico } = useContext(AnnoScolasticoContext)
  const anni: string[] = annoScolastico.split('-')
  const dataMin = `${anni[0]}-09-01`
  const dataMax = `${anni[1]}-08-31`

  //===================
  // LOCAL STATE
  //===================
  const [dataSel, setDataSel] = useState<string>(
    moment(dataMax, 'YYYY-MM-DD', true).isBefore(moment())
      ? dataMax
      : moment().format('YYYY-MM-DD')
  )
  const [presenze, setPresenze] = useState([])
  const [totali, setTotali] = useState<Presenza>(totaliIniziali)

  //===================
  // HOOKS QUERIES
  //===================
  const { data: presenzeGiorno = [], isFetching } = usePresenzeDataCucina({
    servizioId: servizioId,
    dataPresenze: dataSel,
  })

  //===================
  // EFFECTS
  //===================
  useEffect(() => {
    const presenze = presenzeGiorno.map((presenzaGiorno: any) => {
      const presenzeScuola = presenzaGiorno.presenze
        ? ['P', 'A', 'B', 'M'].reduce((accScuola: Presenza, tipo: string) => {
            const presenzeTipo = presenzaGiorno.presenze.find(
              (presenza: any) => presenza.tipo === tipo
            )
            accScuola[tipo] = presenzeTipo ? presenzeTipo.count : 0
            return accScuola
          }, {})
        : totaliIniziali
      return {
        ...presenzaGiorno,
        presenze: presenzeScuola,
      }
    })

    const totali = presenze.reduce(
      (accTotali: Presenza, presenzaGiorno: any) => {
        return {
          P: accTotali.P + presenzaGiorno.presenze.P,
          A: accTotali.A + presenzaGiorno.presenze.A,
          B: accTotali.B + presenzaGiorno.presenze.B,
          M: accTotali.M + presenzaGiorno.presenze.M,
        }
      },
      totaliIniziali
    )

    setPresenze(presenze)
    setTotali(totali)
  }, [isFetching])

  return (
    <div className="bg-white py-6">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl leading-tight mb-8">
          <span className="text-xl">Presenze di</span>{' '}
          <InlineEdit
            type={InputType.Date}
            value={dataSel}
            onChange={(v) => setDataSel(v)}
            format={(v) => moment(v, 'YYYY-MM-DD', true).format('dddd D MMMM')}
            viewClass="cursor-pointer text-blue-500"
            editClass="form-input text-lg"
            editProps={{ min: dataMin, max: dataMax }}
          />
        </h1>

        {!!isFetching ? (
          <Loading className="py-12" />
        ) : (
          <table>
            <thead>
              <tr>
                <th className="px-4 sm:px-6 py-2 bg-gray-100"></th>
                <th className="px-4 sm:px-6 py-2 bg-gray-100"></th>
                <th className="px-4 sm:px-6 py-2 bg-gray-100 text-left text-sm leading-4 font-medium text-green-600 tracking-wider text-center">
                  P<span className="hidden sm:inline">resenti</span>
                </th>
                {servizioId === 1 && (
                  <>
                    <th className="px-4 sm:px-6 py-2 bg-gray-100 text-left text-sm leading-4 font-medium text-yellow-600 tracking-wider text-center">
                      B<span className="hidden sm:inline">ianco</span>
                    </th>
                    <th className="px-4 sm:px-6 py-2 bg-gray-100 text-left text-sm leading-4 font-medium text-purple-600 tracking-wider text-center">
                      M<span className="hidden sm:inline">inipasto</span>
                    </th>
                  </>
                )}
                <th className="px-4 sm:px-6 py-2 bg-gray-100 text-left text-sm leading-4 font-medium text-gray-600 tracking-wider text-center border-l-2 border-gray-200">
                  A<span className="hidden sm:inline">ssenti</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {presenze.map((presenza: any) => {
                const numSezioniNonSalvate =
                  presenza.numSezioni - presenza.numSezioniSalvate > 0

                return (
                  <tr
                    key={presenza.scuola.id}
                    className={classNames({
                      'bg-red-100 text-red-600': numSezioniNonSalvate,
                    })}
                  >
                    <td className="px-4 sm:px-6 py-4 border-b border-gray-200 font-semibold">
                      {presenza.scuola && presenza.scuola.nome}
                    </td>
                    <td className="px-4 sm:px-6 py-4 border-b border-gray-200 font-semibold text-center">
                      {numSezioniNonSalvate ? (
                        <span className="text-red-600 flex justify-center items-center">
                          {presenza.numSezioniSalvate} / {presenza.numSezioni}
                          <FiAlertTriangle className="ml-2" />
                        </span>
                      ) : (
                        <span className="text-green-400 text-xl flex justify-center items-center">
                          <FiCheck />
                        </span>
                      )}
                    </td>

                    <td className="px-4 sm:px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-center">
                      {presenza.presenze.P}
                    </td>
                    {servizioId === 1 && (
                      <>
                        <td className="px-4 sm:px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-center">
                          {presenza.presenze.B}
                        </td>
                        <td className="px-4 sm:px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-center">
                          {presenza.presenze.M}
                        </td>
                      </>
                    )}
                    <td className="px-4 sm:px-6 py-4 whitespace-no-wrap border-l-2 border-b border-gray-200 text-gray-500 text-center">
                      {presenza.presenze.A}
                    </td>
                  </tr>
                )
              })}
              <tr>
                <td className="px-4 sm:px-6 py-4 whitespace-no-wrap border-b border-t border-gray-200"></td>
                <td className="px-4 sm:px-6 py-4 whitespace-no-wrap border-b border-t border-gray-200"></td>
                <td className="px-4 sm:px-6 py-4 whitespace-no-wrap border-b border-t border-gray-200 text-center text-xl font-bold text-green-500">
                  {totali.P}
                </td>
                {servizioId === 1 && (
                  <>
                    <td className="px-4 sm:px-6 py-4 whitespace-no-wrap border-b border-t border-gray-200 text-center text-xl font-bold text-yellow-600">
                      {totali.B}
                    </td>
                    <td className="px-4 sm:px-6 py-4 whitespace-no-wrap border-b border-t border-gray-200 text-center text-xl font-bold text-purple-500">
                      {totali.M}
                    </td>
                  </>
                )}
                <td className="px-4 sm:px-6 py-4 whitespace-no-wrap border-b border-t border-l-2 border-gray-200 text-center text-xl text-gray-500">
                  {totali.A}
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}

export default PresenzeCucina
