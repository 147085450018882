import { useQuery } from 'react-query'
import axios from 'axios'

interface GetSezioniArgs {
  scuolaId: number
  annoScolastico?: string
}

const getSezioniByScuolaId = async (_: string, { scuolaId, annoScolastico }: GetSezioniArgs) => {
  const queryAnnoScolastico = annoScolastico ? `?annoScolastico=${annoScolastico}` : '' 
  const { data } = await axios.get(`/scuole/${scuolaId}/sezioni${queryAnnoScolastico}`)
  return data.results
}

const useSezioniByScuolaId = ({ scuolaId, annoScolastico }: GetSezioniArgs) => {
  return useQuery(
    !!scuolaId && ['sezioniByScuolaId', { scuolaId, annoScolastico }],
    getSezioniByScuolaId, {
      // 20 minuti
      staleTime: 20 * 60 * 1000
    }
  )
}

export default useSezioniByScuolaId
