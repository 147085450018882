import { useQuery } from 'react-query'
import axios from 'axios'

const getScuoleByServizioId = async (_: string, servizioId: number) => {
  const { data } = await axios.get(`/servizi/${servizioId}/scuole/`)
  return data
}

const useScuoleByServizioId = (servizioId: number) => {
  return useQuery(
    !!servizioId && ['scuoleByServizioId', servizioId],
    getScuoleByServizioId,
    {
      // 30 minuti
      staleTime: 30 * 60 * 1000,
    }
  )
}

export default useScuoleByServizioId
